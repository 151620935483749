/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from "react";
import * as DOMUtils from "history/DOMUtils";
import ModalBaseView from "../aem/ModalBaseView";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import { objectToQueryString, queryStringToCorrectEnv, queryStringToObject } from "../../../utils";
import * as Status from "./styles/StatusStyles";
import * as Flex from "../../../styles/toyota/Flexbox";
import {
    COMPONENT_CAR_FILTER,
    COMPONENT_CAR_FILTER_HEADER,
    ENDPOINT_COMPONENT_MAP,
    ComponentType,
    COMPONENT_LEASING_FILTER,
    COMPONENT_LEASING_HEADER,
    COMPONENT_CAR_CONFIG,
    COMPONENT_BUILDANDBUY,
} from "../../../../shared-logic/server/components";
import { useCommonSelector } from "../../../redux/commonStore";
import { ModalViewType, QueryObjectType } from "../../../types/CommonTypes";
import { ModalToolsType } from "../../../utils/modalConstants";
import { QueryType } from "../../../server/types";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../styles/v2/toyota/globals/IconWrapper";
import { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import { useCommonLabel } from "../../../utils/commonLabels";
import { useToolsModalEnvs } from "../../../utils/environments";

type LinkType = {
    title: string;
    href?: string;
    redirect?: boolean; // will redirect to href instead of opening new tab
    onClick?: () => void;
};

const Link = ({ link }: { link: LinkType }): JSX.Element => (
    <a
        href={link.href || "#"}
        onClick={link.onClick}
        target={link.redirect || !link.href ? undefined : "_blank"}
        rel="noopener noreferrer"
    >
        {link.title}
    </a>
);

export type QueryBuilderStateType = Partial<Record<keyof QueryType, QueryObjectType>>;

/**
 * Returns the target URL with the query params provided in the queryObjects array.
 */
const getCombinedToolUrl = (
    targetUrl: string,
    queryObjects: QueryBuilderStateType,
    commonSettings: CommonSettingsType,
    extraQueryParams?: Record<string, any>,
): string => {
    // convert QueryBuilderStateType to more basic type that objectToQueryString can use
    const builderQueryParams = Object.entries(queryObjects).reduce((newQueryObjectMap, [queryName, options]) => {
        if (!options.inputTouched || options.currentValue === "") return newQueryObjectMap;
        return {
            ...newQueryObjectMap,
            [queryName]: options.currentValue,
        };
    }, {} as Record<keyof QueryType, unknown>);

    const location = DOMUtils.canUseDOM ? window.location : { origin: "", pathname: "", search: "" };
    const windowQueryParams: Record<string, string> = queryStringToObject(location.search);
    const queryParams = commonSettings.query ? { ...commonSettings.query } : windowQueryParams;

    if (targetUrl.includes("localhost")) delete queryParams?.useGlobalStore; // localhost car-filter will not work with useGlobalStore, so we remove it.

    let queryString = objectToQueryString({ ...queryParams, ...extraQueryParams, ...builderQueryParams });

    // In some occasions we want to change the queryString to the correct variant according to the environment.
    // E.g. build CUSTOMIZE -> apheleia CONFIGURE.
    // Note that Apheleia has it's own ToolsModal.
    if (commonSettings.component === COMPONENT_BUILDANDBUY) {
        queryString = queryStringToCorrectEnv(queryString, commonSettings.component, targetUrl);
    }

    const seperator = queryString ? "?" : "";

    return `${targetUrl}${seperator}${queryString}`;
};

export type ToolsModalItemType =
    | false
    | {
          title?: string;
          links: (false | LinkType)[];
      };

// With the recent introduction of the Top Filters components (UCL & Leasing Filters),
// there's now the possibility of having 2 components on the same page (the filter, and the filter header).
// As this causes the issue of the wrong component being defined in commonSettings.component,
// we now need to redefine the component to ensure that the correct component is used in the Tools Modal.
const redefineComponent = (component: ComponentType): ComponentType => {
    if (component === COMPONENT_CAR_FILTER_HEADER) return COMPONENT_CAR_FILTER;
    if (component === COMPONENT_LEASING_HEADER) return COMPONENT_LEASING_FILTER;

    return component;
};

const ToolsModal = (props: ModalViewType<ModalToolsType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { extraItems, extraQueryBuilderOptions } = modalSettings;

    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const { country, language, brand, component } = commonSettings;

    const standaloneEnvs = useToolsModalEnvs();

    const redefinedComponent = redefineComponent(component);

    const initialQueryBuilderState = useMemo(
        (): QueryBuilderStateType => ({
            variantBrand: {
                label: "Override layout variant brand",
                queryValues: ["toyota", "lexus"],
                isDropdown: true,
                currentValue: commonSettings.query.variantBrand || "",
            },
            showDictionaryKeys: {
                label: "Show dictionary keys",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.showDictionaryKeys || false,
            },
            disableTokens: {
                label: "Disable tokens",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.disableTokens || false,
            },
            disableErrorModal: {
                label: "Disable error modal",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.disableErrorModal || false,
            },
            logtracking: {
                label: "Log tracking events",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.logtracking || false,
            },
            useMockData: {
                label: "Use Mock Data",
                queryValues: ["true", "false"],
                currentValue: commonSettings.query.useMockData || false,
            },
            ...extraQueryBuilderOptions,
        }),
        [],
    );
    const [queryBuilderState, setQueryBuilderState] = useState<QueryBuilderStateType>(initialQueryBuilderState);

    const handleQueryBuilderChange = (
        queryName: string,
        isDropdown?: boolean,
        event?: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        const queryObject = (queryBuilderState as Record<string, QueryObjectType>)[queryName];
        const initialQueryObject = (initialQueryBuilderState as Record<string, QueryObjectType>)[queryName];
        if (!queryObject || !initialQueryObject) return;

        if (isDropdown && event?.target) {
            const newValue = event.target.value || initialQueryObject.currentValue;
            const newState = {
                ...queryBuilderState,
                [queryName]: { ...queryObject, currentValue: newValue, inputTouched: true },
            };
            setQueryBuilderState(newState);
            return;
        }

        // checkbox
        const newState = {
            ...queryBuilderState,
            [queryName]: { ...queryObject, currentValue: !queryObject.currentValue, inputTouched: true },
        };
        setQueryBuilderState(newState);
    };

    const initialEnvironment =
        standaloneEnvs.find((env) => env.url.includes(window.location.host)) ||
        standaloneEnvs[standaloneEnvs.length - 1]; // If none of the standalone environments match the current environment, use the last one (current environment)
    const [selectedHost, setSelectedHost] = useState(initialEnvironment);

    const isApheleia = selectedHost.name.toLowerCase().includes("apheleia");
    const componentString = ENDPOINT_COMPONENT_MAP[isApheleia ? COMPONENT_CAR_CONFIG : redefinedComponent];

    const combinedUrl = getCombinedToolUrl(
        `${selectedHost?.url || ""}${!selectedHost.isIntegrated ? `/${country}/${language}/${componentString}` : ""}`,
        queryBuilderState,
        commonSettings,
        selectedHost?.addBrandParam ? { brand } : undefined,
    );

    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView onClose={close} show={show} size="md">
            <Modal.Header hasBackground>
                <Modal.Title>Tools - {modalSettings.componentName}</Modal.Title>
                <Modal.Button type="button" onClick={close} aria-label={closeLabel}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Status.Wrapper>
                <Status.Row>
                    <Flex.Col>
                        <strong>Environment</strong>
                        <select
                            onChange={(event) => {
                                const env = standaloneEnvs.find(
                                    (environment) => environment.url === event.target.value,
                                );
                                if (env) setSelectedHost(env);
                            }}
                            value={selectedHost?.url || ""}
                        >
                            <option value="">--- Select environment ---</option>
                            {standaloneEnvs.map((env) => (
                                <option key={env.name} value={env.url}>
                                    {env.name}
                                </option>
                            ))}
                        </select>
                    </Flex.Col>
                </Status.Row>
                {Object.entries(queryBuilderState).map(([queryName, option]) => {
                    if (option.isDropdown) {
                        return (
                            <Status.Row key={queryName}>
                                <Flex.Col as="label">
                                    <span>{option.label}</span>
                                    <select
                                        value={option.currentValue ? option.currentValue.toString() : ""}
                                        onChange={(event) => handleQueryBuilderChange(queryName, true, event)}
                                    >
                                        <option value="">--- Select to override ---</option>
                                        {option.queryValues.map((value) => (
                                            <option key={value} value={value as string}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </Flex.Col>
                            </Status.Row>
                        );
                    }

                    return (
                        <Status.Row key={queryName}>
                            <Flex.Col as="label">
                                <input
                                    type="checkbox"
                                    checked={!!option.currentValue}
                                    onChange={() => handleQueryBuilderChange(queryName)}
                                />
                                <span>{option.label}</span>
                            </Flex.Col>
                        </Status.Row>
                    );
                })}
                <Status.Row>
                    <Flex.Col>
                        <strong>
                            New link <small>(click to open)</small>
                        </strong>
                        <Status.Code>
                            <a href={combinedUrl} rel="noopener noreferrer">
                                {combinedUrl}
                            </a>
                        </Status.Code>
                    </Flex.Col>
                </Status.Row>

                <hr />

                <strong>Other links: </strong>
                {extraItems.filter(Boolean).map(
                    (item, index) =>
                        item &&
                        item.links.filter(Boolean).length > 0 && (
                            <Status.Row key={index}>
                                <Flex.Col>
                                    {item.links.length > 1 ? (
                                        <Status.LinksWrapper>
                                            <strong>{item.title}</strong>
                                            {item.links.map(
                                                (link, linkIndex) => link && <Link key={linkIndex} link={link} />,
                                            )}
                                        </Status.LinksWrapper>
                                    ) : (
                                        item.links[0] && <Link link={item.links[0]} />
                                    )}
                                </Flex.Col>
                            </Status.Row>
                        ),
                )}
            </Status.Wrapper>
        </ModalBaseView>
    );
};

export default ToolsModal;
