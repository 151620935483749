import { useCommonSelector } from "../redux/commonStore";
import { EnvironmentEnum } from "../utils";

export const environmentMap = {
    apheleia: {
        name: "Apheleia",
        domains: {
            [EnvironmentEnum.Development]: "https://aph-webcarconfig-dev.{brand}-europe.com",
            [EnvironmentEnum.Acceptance]: "https://aph-webcarconfig-acc.{brand}-europe.com",
            [EnvironmentEnum.Preview]: "https://aph-webcarconfig-prev.{brand}-europe.com",
            [EnvironmentEnum.Production]: "https://aph-webcarconfig.{brand}-europe.com",
        },
        entrypoints: {
            carconfig: "/{country}/{language}/car-config?tyCode={tyCode}",
        },
    },
    build: {
        name: "NewCars",
        domains: {
            [EnvironmentEnum.Development]: "https://dxp-webcarconfig-dev.{brand}-europe.com",
            [EnvironmentEnum.Acceptance]: "https://dxp-webcarconfig-acc.{brand}-europe.com",
            [EnvironmentEnum.Preview]: "https://dxp-webcarconfig-prev.{brand}-europe.com",
            [EnvironmentEnum.Production]: "https://dxp-webcarconfig.{brand}-europe.com",
        },
        entrypoints: {
            build: "/{country}/{language}/build-and-buy?path=customize&tyCode={tyCode}",
            leasing: "/{country}/{language}/leasing-details?path=customize&tyCode={tyCode}",
        },
    },
    usc: {
        name: "UsedStockCars",
        domains: {
            [EnvironmentEnum.Development]: "https://usc-webcomponentsdev.toyota-europe.com",
            [EnvironmentEnum.Acceptance]: "https://usc-webcomponentsacc.toyota-europe.com",
            [EnvironmentEnum.Preview]: "https://usc-webcomponentsprev.toyota-europe.com",
            [EnvironmentEnum.Production]: "https://usc-webcomponents.toyota-europe.com",
        },
        entrypoints: {
            pdp: "/{country}/{language}/used-stock-cars?brand={brand}&uscContext={uscContext}&tyCode={tyCode}",
        },
    },
} as const;

// Mainly used for loading clientlibs, we use the screens domain since CORS / auth is mostly disabled there
export const dxpWebsiteMap = {
    [EnvironmentEnum.Development]: "https://screens-dev.toyota.eu",
    [EnvironmentEnum.Acceptance]: "https://screens-uat.toyota.eu",
    [EnvironmentEnum.Preview]: "https://screens.toyota.eu", // no separate env for preview code
    [EnvironmentEnum.Production]: "https://screens.toyota.eu",
} as const;

export type ToolsModalEnvType = {
    name: string;
    url: string;
    addBrandParam?: boolean;
    isIntegrated?: boolean;
};

export const useToolsModalEnvs = (): ToolsModalEnvType[] => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const { brand } = commonSettings;

    // Format the environmentMap to a flat array format used by the toolsmodal
    const standaloneEnvs = Object.entries(environmentMap).reduce((list, [app, data]) => {
        const newEnv: ToolsModalEnvType[] = Object.entries(data.domains).map(([env, domain], index) => ({
            name: `${data.name} ${env.slice(0, index > 1 ? 4 : 3).toUpperCase()}`, // names are: DEV, UAT, PREV, PROD
            url: domain.replace("{brand}", brand), // needs to happen on runtime as env variables are not loaded in when defining a global variable like envMap
            addBrandParam: app === "usc", // usc doesn't have a lexus server, instead a queryparam is used
        }));
        return [...list, ...newEnv];
    }, [] as ToolsModalEnvType[]);

    standaloneEnvs.push({
        name: "Local",
        url: `http://localhost:${brand === "toyota" ? 5001 : 5002}`,
    });

    // Replace the original standalone environment and show that it's the current environment
    if (standaloneEnvs.find((env) => env.url === window.location.origin)) {
        standaloneEnvs.map((env) => {
            if (env.url === window.location.origin) {
                env.name = `${env.name} (current)`;
            }
            return env;
        });
    } else {
        // None of the standalone environments match the current environment, add it to the list
        standaloneEnvs.push({
            name: "Current",
            url: window.location.origin + window.location.pathname,
            isIntegrated: true, // This is an integrated environment, so we don't need to add the country/language/component string to the URL as this will be handled by location.pathname
        });
    }

    return standaloneEnvs;
};
