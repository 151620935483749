import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    position: relative;
    max-width: 100%;
`;

export const Popup = styled.div`
    position: absolute;
    inset-inline: 0;
    min-width: 256px;
    background: ${theme.colors.primaryWhite};
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: ${theme.zIndex.popover};

    @supports not (inset-inline: 0) {
        left: 0;
        right: 0;
    }
`;
