import {
    LocationDealerGroupType,
    LocationDealerType,
    LocationFilterIdType,
    LocationRangeType,
    LocationRangeValueType,
    MinMaxSliderFilterIdType,
    MultipleChoiceFilterIdType,
    PriceFilterIdType,
    ValueResultCountType,
} from "../../utils/constants/filterConstants";
import { CarFilterIdType, UscContext } from "../../../shared-logic/types/UscCommonTypes";
import { FinanceOptionType } from "../../../../common-deprecated/types/CommonTypes";
import { CoordinateType, DealerResultType } from "../../../../common-deprecated/utils/dealerConstants";
import { SupportedSaveContexts } from "../../utils/localStorage";
import { SimilarCarDataType } from "../../utils/constants/similarCarConstants";
import { CarFiltersReducerType } from "../reducers/CarFiltersReducer";
import { UsedCarCompareAddCarsType } from "../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";
import { CarFilterParamType } from "../../utils/params";
import { SliderButtonType } from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

// ----------------------------------------------------------------------
// Common
// ----------------------------------------------------------------------
export const CAR_FILTER_INIT: "carFilter/init" = "carFilter/init";
export const CAR_FILTER_SHOW_MORE: "carFilter/showMore" = "carFilter/showMore";
export const CAR_FILTER_SET_CURRENT_FILTER: "carFilter/setCurrentFilter" = "carFilter/setCurrentFilter";
export const CAR_FILTER_SET_ACTIVE_FILTERS: "carFilter/setActiveFilters" = "carFilter/setActiveFilters";
export const CAR_FILTER_SET_INITIALIZED: "carFilter/setInitialized" = "carFilter/setInitialized";
export const CAR_FILTER_SET_PAGE: "carFilter/setPage" = "carFilter/setPage";
export const CAR_FILTER_SET_TOTAL_PAGE_COUNT: "carFilter/setTotalPageCount" = "carFilter/setTotalPageCount";

export type CarFilterShowMoreType = { type: typeof CAR_FILTER_SHOW_MORE; show: boolean };
export type CarFilterSetCurrentFilterType = {
    type: typeof CAR_FILTER_SET_CURRENT_FILTER;
    currentFilter: UscContext;
};
type CarFilterInitType = {
    type: typeof CAR_FILTER_INIT;
    initState: Partial<CarFiltersReducerType>;
    filter: UscContext;
    params?: CarFilterParamType;
};
export type CarFilterSetActiveFiltersType = {
    type: typeof CAR_FILTER_SET_ACTIVE_FILTERS;
    activeFilters: CarFilterIdType[];
};
export type CarFilterSetInitializedType = { type: typeof CAR_FILTER_SET_INITIALIZED; initialized: boolean };
export type CarFilterSetPageType = { type: typeof CAR_FILTER_SET_PAGE; page: number };
export type CarFilterSetTotalPageCountType = { type: typeof CAR_FILTER_SET_TOTAL_PAGE_COUNT; count: number };

export const initCarFilters = (
    initState: Partial<CarFiltersReducerType>,
    filter: UscContext,
    params?: CarFilterParamType,
): CarFilterInitType => ({
    type: CAR_FILTER_INIT,
    initState,
    filter,
    params,
});
export const showMoreFilters = (show: boolean): CarFilterShowMoreType => ({
    type: CAR_FILTER_SHOW_MORE,
    show,
});
export const setCurrentFilter = (currentFilter: UscContext): CarFilterSetCurrentFilterType => ({
    type: CAR_FILTER_SET_CURRENT_FILTER,
    currentFilter,
});
export const setActiveFilters = (activeFilters: CarFilterIdType[]): CarFilterSetActiveFiltersType => ({
    type: CAR_FILTER_SET_ACTIVE_FILTERS,
    activeFilters,
});
export const setPage = (page: number): CarFilterSetPageType => ({
    type: CAR_FILTER_SET_PAGE,
    page,
});
export const setTotalPageCount = (count: number): CarFilterSetTotalPageCountType => ({
    type: CAR_FILTER_SET_TOTAL_PAGE_COUNT,
    count,
});

// Don't use this action except if there is a very good reason to.
// initCarFilters should be used in most cases as that triggers proper init logic.
export const setInitialized = (initialized: boolean): CarFilterSetInitializedType => ({
    type: CAR_FILTER_SET_INITIALIZED,
    initialized,
});

// ----------------------------------------------------------------------
// Similar cars
// ----------------------------------------------------------------------
export const CAR_FILTER_SET_SIMILAR_CAR_ID = "carFilter/similarCar/setId";
export const CAR_FILTER_SET_SIMILAR_CAR = "carFilter/similarCar/setCar";
export const CAR_FILTER_SET_SIMILAR_CAR_ATTEMPT = "carFilter/similarCar/setFallback";
export const CAR_FILTER_SET_NO_CARS_FOUND = "carFilter/similarCar/setNoCarsFound";

type CarFilterSetSimilarCarId = { type: typeof CAR_FILTER_SET_SIMILAR_CAR_ID; id: string };
type CarFilterSetSimilarCar = { type: typeof CAR_FILTER_SET_SIMILAR_CAR; similarCar: SimilarCarDataType | null };
type CarFilterSetSimilarCarAttempt = { type: typeof CAR_FILTER_SET_SIMILAR_CAR_ATTEMPT; attempt: number };
type CarFilterSetNoCarsFound = { type: typeof CAR_FILTER_SET_NO_CARS_FOUND; noCarsFound: boolean };

export const setSimilarCarId = (id: string): CarFilterSetSimilarCarId => ({ type: CAR_FILTER_SET_SIMILAR_CAR_ID, id });
export const setSimilarCar = (similarCar: SimilarCarDataType | null): CarFilterSetSimilarCar => ({
    type: CAR_FILTER_SET_SIMILAR_CAR,
    similarCar,
});
export const setSimilarCarAttempt = (attempt: number): CarFilterSetSimilarCarAttempt => ({
    type: CAR_FILTER_SET_SIMILAR_CAR_ATTEMPT,
    attempt,
});
export const setNoSimilarCarsFound = (noCarsFound: boolean): CarFilterSetNoCarsFound => ({
    type: CAR_FILTER_SET_NO_CARS_FOUND,
    noCarsFound,
});

// ----------------------------------------------------------------------
// Saved cars
// ----------------------------------------------------------------------
export const CAR_FILTER_ADD_SAVED_CAR = "carFilter/saved/addCar";
export const CAR_FILTER_REMOVE_SAVED_CAR = "carFilter/saved/removeCar";
export const CAR_FILTER_SET_SAVED_CARS = "carFilter/saved/setCars";
export const CAR_FILTER_SHOW_SAVED_CARS = "carFilter/saved/showSavedCars";

export type CarFilterAddSavedCar = {
    type: typeof CAR_FILTER_ADD_SAVED_CAR;
    vehicleForSaleId: string;
    context: SupportedSaveContexts;
};
export type CarFilterRemoveSavedCar = {
    type: typeof CAR_FILTER_REMOVE_SAVED_CAR;
    vehicleForSaleId: string;
    context: SupportedSaveContexts;
};
export type CarFilterSetSavedCar = {
    type: typeof CAR_FILTER_SET_SAVED_CARS;
    vehicleForSaleIds: string[];
    context: SupportedSaveContexts;
};
export type CarFilterShowSavedCars = { type: typeof CAR_FILTER_SHOW_SAVED_CARS; show: boolean };

export const addSavedCar = (context: SupportedSaveContexts, vehicleForSaleId: string): CarFilterAddSavedCar => ({
    type: CAR_FILTER_ADD_SAVED_CAR,
    context,
    vehicleForSaleId,
});
export const removeSavedCar = (context: SupportedSaveContexts, vehicleForSaleId: string): CarFilterRemoveSavedCar => ({
    type: CAR_FILTER_REMOVE_SAVED_CAR,
    context,
    vehicleForSaleId,
});
export const setSavedCars = (context: SupportedSaveContexts, vehicleForSaleIds: string[]): CarFilterSetSavedCar => ({
    type: CAR_FILTER_SET_SAVED_CARS,
    context,
    vehicleForSaleIds,
});
export const showOnlySavedCars = (show: boolean): CarFilterShowSavedCars => ({
    type: CAR_FILTER_SHOW_SAVED_CARS,
    show,
});

// ----------------------------------------------------------------------
// Min max slider actions
// ----------------------------------------------------------------------
export const CAR_FILTER_SET_SLIDER_VALUE: "carFilter/slider/setValue" = "carFilter/slider/setValue";
export const CAR_FILTER_RESET_SLIDER: "carFilter/slider/reset" = "carFilter/slider/reset";

type CarFilterSetSliderValueType = {
    type: typeof CAR_FILTER_SET_SLIDER_VALUE;
    valueType: SliderButtonType;
    value: number;
    filterId: MinMaxSliderFilterIdType;
};
type CarFilterResetSliderType = { type: typeof CAR_FILTER_RESET_SLIDER; filterId: MinMaxSliderFilterIdType };

export const setSliderValue = (
    valueType: SliderButtonType,
    value: number,
    filterId: MinMaxSliderFilterIdType,
): CarFilterSetSliderValueType => ({
    type: CAR_FILTER_SET_SLIDER_VALUE,
    valueType,
    value,
    filterId,
});
export const resetSlider = (filterId: MinMaxSliderFilterIdType): CarFilterResetSliderType => ({
    type: CAR_FILTER_RESET_SLIDER,
    filterId,
});

// ----------------------------------------------------------------------
// Multiple choice actions
// ----------------------------------------------------------------------
export const CAR_FILTER_SELECT_MULTI: "carFilter/multi/select" = "carFilter/multi/select";
export const CAR_FILTER_RESET_MULTI: "carFilter/multi/reset" = "carFilter/multi/reset";
export const CAR_FILTER_UPDATE_MULTI_COUNT: "carFilter/multi/updateCount" = "carFilter/multi/updateCount";

export type CarFilterSelectMultiType = {
    type: typeof CAR_FILTER_SELECT_MULTI;
    ids: string[] | "all";
    selected: boolean;
    filterId: MultipleChoiceFilterIdType;
};
export type CarFilterResetMultiType = {
    type: typeof CAR_FILTER_RESET_MULTI;
    filterId: MultipleChoiceFilterIdType;
};
type CarFilterUpdateMultiCountType = {
    type: typeof CAR_FILTER_UPDATE_MULTI_COUNT;
    updates: ValueResultCountType;
    filterContext: UscContext;
};

export const setSelectMultiFilter = (
    ids: string[] | "all",
    selected: boolean,
    filterId: MultipleChoiceFilterIdType,
): CarFilterSelectMultiType => ({
    type: CAR_FILTER_SELECT_MULTI,
    selected,
    ids,
    filterId,
});

export const resetMultiFilter = (filterId: MultipleChoiceFilterIdType): CarFilterResetMultiType => ({
    type: CAR_FILTER_RESET_MULTI,
    filterId,
});

export const updateMultiFilterCounts = (
    updates: ValueResultCountType,
    filterContext: UscContext,
): CarFilterUpdateMultiCountType => ({
    type: CAR_FILTER_UPDATE_MULTI_COUNT,
    updates,
    filterContext,
});

// ----------------------------------------------------------------------
// Colour filter actions
// ----------------------------------------------------------------------
// Colour filter is based on multiple choice filter, so any multiple choice filter action should work as well.
export const CAR_FILTER_SET_COLOUR_BI_TONE: "carFilter/colour/setBiTone" = "carFilter/colour/setBiTone";

export type CarFilterColourSetBiTone = { type: typeof CAR_FILTER_SET_COLOUR_BI_TONE; selected: boolean };

export const setBiTone = (selected: boolean): CarFilterColourSetBiTone => ({
    type: CAR_FILTER_SET_COLOUR_BI_TONE,
    selected,
});

// ----------------------------------------------------------------------
// Model filter actions
// ----------------------------------------------------------------------
export const CAR_FILTER_UPDATE_MODEL_FILTER: "carFilter/model/update" = "carFilter/model/update";
export const CAR_FILTER_RESET_MODEL_FILTER: "carFilter/model/reset" = "carFilter/model/reset";

export type CarFilterUpdateModelFilter = {
    type: typeof CAR_FILTER_UPDATE_MODEL_FILTER;
    ids: string[] | "all";
};

// ----------------------------------------------------------------------
// Mileage filter actions
// ----------------------------------------------------------------------
export const CAR_FILTER_SET_ZERO_MILEAGE: "carFilter/mileage/setZero" = "carFilter/mileage/setZero";

export type CarFilterSetZeroMileage = { type: typeof CAR_FILTER_SET_ZERO_MILEAGE; set: boolean };

export const setZeroMileage = (set: boolean): CarFilterSetZeroMileage => ({
    type: CAR_FILTER_SET_ZERO_MILEAGE,
    set,
});

// ----------------------------------------------------------------------
// Price filter
// ----------------------------------------------------------------------
export const CAR_FILTER_SET_PRICE: "carFilter/price/setPrice" = "carFilter/price/setPrice";
export const CAR_FILTER_RESET_PRICE: "carFilter/price/reset" = "carFilter/price/reset";
export const CAR_FILTER_SET_PRICE_ACTIVE: "carFilter/price/setActive" = "carFilter/price/setActive";

export type CarFilterSetPriceType = {
    type: typeof CAR_FILTER_SET_PRICE;
    financeOption: FinanceOptionType;
    priceType: "min" | "max";
    value: number;
    filterId: PriceFilterIdType;
};
export type CarFilterResetPriceType = { type: typeof CAR_FILTER_RESET_PRICE; filterId: PriceFilterIdType };
export type CarFilterSetPriceActiveType = {
    type: typeof CAR_FILTER_SET_PRICE_ACTIVE;
    financeOption: FinanceOptionType;
    filterId: PriceFilterIdType;
};

export const setPrice = (
    filter: PriceFilterIdType,
    priceType: "min" | "max",
    value: number,
    financeOption: FinanceOptionType,
): CarFilterSetPriceType => ({
    type: CAR_FILTER_SET_PRICE,
    financeOption,
    priceType,
    value,
    filterId: filter,
});

export const resetPriceFilter = (filter: PriceFilterIdType): CarFilterResetPriceType => ({
    type: CAR_FILTER_RESET_PRICE,
    filterId: filter,
});
export const setPriceActive = (
    filter: PriceFilterIdType,
    financeOption: FinanceOptionType,
): CarFilterSetPriceActiveType => ({
    type: CAR_FILTER_SET_PRICE_ACTIVE,
    financeOption,
    filterId: filter,
});

// ----------------------------------------------------------------------
// Location filter
// ----------------------------------------------------------------------
export const CAR_FILTER_SET_DEALER: "carFilter/location/setDealer" = "carFilter/location/setDealer";
export const CAR_FILTER_SET_DEALER_GROUP: "carFilter/location/setDealerGroup" = "carFilter/location/setDealerGroup";
export const CAR_FILTER_SET_LOCATION: "carFilter/location/setLocation" = "carFilter/location/setLocation";
export const CAR_FILTER_SET_USER_COORDS: "carFilter/location/setUserCoords" = "carFilter/location/setUserCoords";
export const CAR_FILTER_SET_LOCATION_RANGE: "carFilter/location/setRange" = "carFilter/location/setRange";
export const CAR_FILTER_RESET_LOCATION: "carFilter/location/reset" = "carFilter/location/reset";
export const CAR_FILTER_SET_DEALER_CACHE: "carFilter/location/setDealerCache" = "carFilter/location/setDealerCache";

export type CarFilterSetDealer = {
    type: typeof CAR_FILTER_SET_DEALER;
    filterId: LocationFilterIdType;
    dealer: LocationDealerType;
};
export type CarFilterSetDealerGroup = {
    type: typeof CAR_FILTER_SET_DEALER_GROUP;
    filterId: LocationFilterIdType;
    dealerGroup: LocationDealerGroupType;
};
export type CarFilterSetLocation = {
    type: typeof CAR_FILTER_SET_LOCATION;
    filterId: LocationFilterIdType;
    location: LocationRangeType;
    // rangeValues/dealerCache should be there except for clearing the location filter.
    // Both values are sourced from the dealer search endpoint.
    rangeValues?: LocationRangeValueType[]; // List of values to be shown in the range dropdown (x dealers in x km range)
    dealerCache?: DealerResultType[]; // List of dealers in range, required to generate elastic search queries
};
export type CarFilterSetUserLocation = {
    type: typeof CAR_FILTER_SET_USER_COORDS;
    filterId: LocationFilterIdType;
    coords: CoordinateType | null;
};
export type CarFilterSetLocationRange = {
    type: typeof CAR_FILTER_SET_LOCATION_RANGE;
    filterId: LocationFilterIdType;
    range: number;
};
export type CarFilterResetLocation = { type: typeof CAR_FILTER_RESET_LOCATION; filterId: LocationFilterIdType };
export type CarFilterSetDealerCache = {
    type: typeof CAR_FILTER_SET_DEALER_CACHE;
    filterId: LocationFilterIdType;
    dealerCache: DealerResultType[];
};

export const setDealer = (filterId: LocationFilterIdType, dealer: LocationDealerType): CarFilterSetDealer => ({
    type: CAR_FILTER_SET_DEALER,
    filterId,
    dealer,
});
export const setDealerGroup = (
    filterId: LocationFilterIdType,
    dealerGroup: LocationDealerGroupType,
): CarFilterSetDealerGroup => ({ type: CAR_FILTER_SET_DEALER_GROUP, filterId, dealerGroup });
export const setLocation = (
    filterId: LocationFilterIdType,
    location: LocationRangeType,
    rangeValues?: LocationRangeValueType[],
    dealerCache?: DealerResultType[],
): CarFilterSetLocation => ({ type: CAR_FILTER_SET_LOCATION, filterId, location, rangeValues, dealerCache });
export const setUserCoords = (
    filterId: LocationFilterIdType,
    coords: CoordinateType | null,
): CarFilterSetUserLocation => ({ filterId, type: CAR_FILTER_SET_USER_COORDS, coords });
export const setLocationRange = (filterId: LocationFilterIdType, range: number): CarFilterSetLocationRange => ({
    type: CAR_FILTER_SET_LOCATION_RANGE,
    filterId,
    range,
});
export const resetLocation = (filterId: LocationFilterIdType): CarFilterResetLocation => ({
    type: CAR_FILTER_RESET_LOCATION,
    filterId,
});

// Actions which impact car filter location filter.
// Mostly here to support triggering distance sort logic depending on location filter changes.
export const CAR_FILTER_LOCATION_ACTIONS = [
    CAR_FILTER_SET_DEALER,
    CAR_FILTER_SET_DEALER_GROUP,
    CAR_FILTER_SET_LOCATION,
    CAR_FILTER_SET_USER_COORDS,
    CAR_FILTER_SET_LOCATION_RANGE,
    CAR_FILTER_RESET_LOCATION,
];

export type LocationFilterActionsType =
    | CarFilterSetDealer
    | CarFilterSetDealerGroup
    | CarFilterSetLocation
    | CarFilterSetUserLocation
    | CarFilterSetLocationRange
    | CarFilterResetLocation;

// Actions which impact car filter results. Whenever one of the following actions is called new filter results will be retrieved.
export const CAR_FILTER_ACTIONS = [
    CAR_FILTER_SET_ZERO_MILEAGE,
    CAR_FILTER_SET_COLOUR_BI_TONE,
    CAR_FILTER_SELECT_MULTI,
    CAR_FILTER_RESET_MULTI,
    CAR_FILTER_SET_SLIDER_VALUE,
    CAR_FILTER_RESET_SLIDER,
    CAR_FILTER_SET_DEALER,
    CAR_FILTER_SET_DEALER_GROUP,
    CAR_FILTER_SET_LOCATION,
    CAR_FILTER_RESET_LOCATION,
    CAR_FILTER_SET_LOCATION_RANGE,
    CAR_FILTER_SET_CURRENT_FILTER,
    CAR_FILTER_SET_PRICE,
    CAR_FILTER_SET_PRICE_ACTIVE,
    CAR_FILTER_RESET_PRICE,
    CAR_FILTER_SHOW_SAVED_CARS,
    CAR_FILTER_UPDATE_MODEL_FILTER,
];

export type CarFiltersActionsType =
    | CarFilterSetPriceType
    | CarFilterResetPriceType
    | CarFilterSetPriceActiveType
    | CarFilterSetZeroMileage
    | CarFilterSetSliderValueType
    | CarFilterResetSliderType
    | CarFilterInitType
    | CarFilterSelectMultiType
    | CarFilterResetMultiType
    | CarFilterColourSetBiTone
    | CarFilterSetDealer
    | CarFilterSetLocation
    | CarFilterResetLocation
    | CarFilterShowMoreType
    | CarFilterSetCurrentFilterType
    | CarFilterSetActiveFiltersType
    | CarFilterSetLocationRange
    | CarFilterSetUserLocation
    | CarFilterUpdateMultiCountType
    | CarFilterSetInitializedType
    | CarFilterSetDealerGroup
    | CarFilterSetDealerCache
    | CarFilterAddSavedCar
    | CarFilterSetSavedCar
    | CarFilterRemoveSavedCar
    | CarFilterShowSavedCars
    | CarFilterSetSimilarCar
    | CarFilterSetSimilarCarId
    | CarFilterSetSimilarCarAttempt
    | CarFilterSetNoCarsFound
    | CarFilterUpdateModelFilter
    | CarFilterSetPageType
    | CarFilterSetTotalPageCountType
    | UsedCarCompareAddCarsType;
