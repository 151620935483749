import { find, mapIds } from "../../../../common-deprecated/utils";
import {
    AccentColourType,
    AvailabilityType,
    ColourType,
    ExtraSetItemType,
    ExtraType,
    PackType,
    PersonalisationItemType,
    PersonalizationType,
    PriceType,
    ServiceProductType,
    TyreType,
    UpholsteryType,
    WheelType,
    WinterWheelType,
} from "../../../../common-deprecated/types/CommonTypes";
import { BasketType } from "../../types/BasketType";
import { CarType } from "../../types/CarType";
import { ItemsType } from "../../../../common-deprecated/redux/typeHelpers";
import Debug from "../../../../common-deprecated/Debug";
import { getDefaultPriceObject } from "../../../../common-deprecated/constants";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";

export const UPHOLSTERY = "upholstery";
export const WHEEL = "wheel";
export const COLOUR = "colour";

// List of supported colouringMode combinations
export const CM_NONE: 0 = 0;
export const CM_BODY_COLOUR: 1 = 1;
export const CM_PRIMARY_ACCENT_COLOUR: 2 = 2;
export const CM_SECONDARY_ACCENT_COLOUR: 4 = 4;

export type ItemAvailabilityType = {
    available: boolean;
    reason?: "colour" | "upholstery";
};

export default class CarDataConsolidator {
    static getColours(colours: ColourType[], basket: BasketType): ColourType[] {
        const basketPackColours = this.getBasketPackColours(basket.packs);
        const filteredColours = colours.filter((item) => item.upholsteriesCarConfigurator.length > 0);
        // Return pack colours if we have them.
        return basketPackColours.length > 0 ? basketPackColours : filteredColours;
    }

    /**
     * Checks if an item is available in combination with the current selected colour and/or upholstery.
     * Returns a boolean to indicite its availability and a reason as of why it's not available.
     *
     * @param item this can be a pack or an extra
     * @param colour
     * @param upholstery
     * @returns {ItemAvailabilityType}
     */
    static isAvailable(
        item: { availability: AvailabilityType } | ExtraType | WinterWheelType,
        colour?: ColourType,
        upholstery?: UpholsteryType,
    ): ItemAvailabilityType {
        const colourCheck =
            !colour || item.availability.colours.length === 0 || item.availability.colours.indexOf(colour.id) > -1;
        const upholsteryCheck =
            !upholstery ||
            item.availability.upholsteries.length === 0 ||
            item.availability.upholsteries.indexOf(upholstery.id) > -1;

        return {
            available: colourCheck && upholsteryCheck,
            reason: !colourCheck ? "colour" : !upholsteryCheck ? "upholstery" : undefined,
        };
    }

    /**
     * Returns all available winterwheels sorted by price (asc)
     */
    static getWinterWheels(
        winterWheels: WinterWheelType[],
        colour: ColourType,
        upholstery: UpholsteryType,
    ): WinterWheelType[] {
        return winterWheels
            .filter((winterWheel) => this.isAvailable(winterWheel, colour, upholstery).available)
            .sort((a, b) => a.price.discount - b.price.discount);
    }

    static getWheels(wheels: WheelType[], basket: BasketType): (ExtraType | WheelType)[] {
        const basketPackWheels = this.getBasketPackWheels(basket);

        // If the wheels are coming from a pack show them; otherwise only show wheels which are available for the selected colour and upholstery
        return basketPackWheels.length > 0
            ? basketPackWheels
            : wheels.filter((wheel) => this.isAvailable(wheel, basket.colour, basket.upholstery).available);
    }

    // TODO This function has typing issues because it returns both upholsteryType and extraType. Can possibly be fixed with some TS magic.
    static getUpholsteries(
        upholsteries: UpholsteryType[],
        equipmentUpholsteries: UpholsteryType[] | null,
        basket: BasketType,
    ): (UpholsteryType | ExtraType)[] {
        const basketPackUpholsteries = this.getBasketPackUpholsteries(basket.packs, !!equipmentUpholsteries);
        // Right now we're ignoring include/exclude rules from pack upholsteries/colours.
        if (basketPackUpholsteries.length > 0) {
            return basketPackUpholsteries;
        }

        const returnedUpholsteries: (UpholsteryType | ExtraType)[] = equipmentUpholsteries
            ? upholsteries.concat(equipmentUpholsteries)
            : upholsteries;

        // Only show upholsteries which are available for the selected colour
        if (basket.colour && basket.colour.upholsteriesCarConfigurator.length > 0) {
            return returnedUpholsteries.filter(
                (upholstery) =>
                    // Check if the upholstery is available for the current colour (the upholstery contains a correct visibility for CarConfigurator)
                    basket.colour.upholsteriesCarConfigurator.indexOf(upholstery.id) !== -1 ||
                    // Or if it's an accessory upholstery, check if its available for the current selected colour
                    (upholstery.objectType === "extra" &&
                        upholstery.isUpholstery &&
                        ((upholstery.availability.colours.length > 0 &&
                            upholstery.availability.colours.indexOf(basket.colour.id)) as number) > -1),
            );
        }

        return returnedUpholsteries;
    }

    /**
     * Generate the visually available set items used to show in the tooltip
     */
    static getSetItems = (
        extra: ExtraType,
        colour: ColourType,
        upholstery: UpholsteryType,
        primaryAccentColour?: AccentColourType | null,
        secondaryAccentColour?: AccentColourType | null,
    ): ExtraSetItemType[] => {
        if (extra.setItems.length === 0) return [];

        const setItems = extra.setItems.filter(
            (setItem) =>
                // Only show extras which are available for the current colour & upholstery combination.
                CarDataConsolidator.isAvailable(setItem, colour, upholstery).available &&
                // Also check the colouringmode
                // @ts-ignore
                CarDataConsolidator.validatesForColourMode(setItem, {
                    colour,
                    primaryAccentColour,
                    secondaryAccentColour,
                }),
        );
        if (setItems.length === 0) {
            // There should always be an active setItem, if this is not the case log an error
            // Log an error if this is the case, the rest of the app should keep working though.
            Debug.error(`No active set item found for ${extra.name}. Configuration error?`);
        }
        return setItems;
    };

    /**
     * Get the value from within the personalization section, meaning color, wheel or upholstery.
     * In case of an accessory upholstery, this method also mutates the price if there is a price for the default regular upholstery.
     */
    static getPersonalizationValue(
        personalization: PersonalizationType,
        basket: BasketType,
        car: CarType,
    ): PersonalisationItemType {
        const value = typeof basket[personalization] !== "undefined" ? basket[personalization] : car[personalization];
        // In case of upholsteries; also check for equipment upholsteries!
        if (personalization === "upholstery") {
            const upholsteryExtra = basket.extras.concat(basket.packExtras).find((extra) => extra.isUpholstery);
            if (upholsteryExtra) {
                // In the Accessory upholstery shown, calculate in the default price of the upholstery! The default upholstery is set through the basket logic in combination with getDefaultUpholstery
                const upholPrice = basket.upholstery.price;
                // If it is part of a pack; don't adjust the price. The price adjustment is already taken care of inside the pack price calculation, see also this.getPackAccessoryUpholsteryPrice
                if (this.findPackEquipment(upholsteryExtra, basket.packs)) return upholsteryExtra;
                return {
                    ...upholsteryExtra,
                    price: {
                        ...getDefaultPriceObject(),
                        cash: upholPrice.cash + upholsteryExtra.price.cash,
                        monthly: upholPrice.monthly + upholsteryExtra.price.monthly,
                        discount: upholPrice.discount + upholsteryExtra.price.discount,
                        onlineCashDiscount: upholPrice.onlineCashDiscount + upholsteryExtra.price.onlineCashDiscount,
                        onlineMonthlyDiscount:
                            upholPrice.onlineMonthlyDiscount + upholsteryExtra.price.onlineMonthlyDiscount,
                    },
                };
            }
        }
        return value;
    }

    /**
     * Method which returns the pack price with the added upholstery price where applies
     */
    static getPackAccessoryUpholsteryPrice<T extends { price: PriceType }>(
        pack: PackType,
        upholsteries: T[],
    ): PriceType {
        const { visualItems, price } = pack;
        // If the pack contains accessory upholsteries, but no regular upholsteries...
        if (visualItems && visualItems.upholsteries.length === 0 && visualItems.equipmentUpholsteries.length > 0) {
            // Get the default upholstery available for the car, the cheapest
            const defaultUpholstery = this.getDefaultUpholstery(upholsteries);
            return {
                cash: defaultUpholstery.price.cash + (pack.price ? pack.price.cash : 0),
                discount: defaultUpholstery.price.discount + (pack.price ? pack.price.discount : 0),
                monthly: defaultUpholstery.price.monthly + (pack.price && pack.price.monthly ? pack.price.monthly : 0),
                exclVat: defaultUpholstery.price.exclVat + (pack.price ? pack.price.exclVat : 0),
                highlight: false,
                licenseFee: 0,
                promotions: [],
                onlineCashDiscount:
                    defaultUpholstery.price.onlineCashDiscount + (pack.price ? pack.price.onlineCashDiscount : 0),
                onlineMonthlyDiscount:
                    defaultUpholstery.price.onlineMonthlyDiscount + (pack.price ? pack.price.onlineMonthlyDiscount : 0),
                onlineMonthlyPromotions: [],
                onlineCashPromotions: [],
            };
        }
        return price;
    }

    /**
     * Method which returns the cheapest, default upholstery
     */
    static getDefaultUpholstery<T extends { price: PriceType; index?: number }>(upholsteries: T[]): T {
        const upholsteryArray = [...upholsteries];

        return upholsteryArray.sort((a, b) => {
            if (!a.price.cash && !b.price.cash) {
                return 0;
            }
            if (!a.price.cash) return -1;
            if (!b.price.cash) return 1;
            if (a.price.cash > b.price.cash) return 1;
            return -1;
        })[0];
    }

    /**
     * Get the wheels part of selected packs in the basket
     */
    static getBasketPackWheels(basket: BasketType): (ExtraType | WheelType)[] {
        return this.getPackEquipment(basket.packs).reduce((wheels: (ExtraType | WheelType)[], equipment) => {
            if (equipment.isWheel) wheels.push(equipment);
            return wheels;
        }, []);
    }

    /**
     * Get colours part of selected packs in the basket.
     */
    static getBasketPackColours(packs: PackType[]): ColourType[] {
        return packs.reduce((acc: ColourType[], pack) => {
            const packEquipment = this.getPackEquipment([pack]);
            // Get a list of all pack colours for this particular pack in the iteration
            const packColours = packEquipment.reduce<ColourType[]>((colourAcc, equipment) => {
                if (equipment.objectType === "extra" && !equipment.isWheel && equipment.exteriorColours.length) {
                    // Still need to concatenate here since the different colour types are defined through different pack-equipment items
                    colourAcc = colourAcc.concat(equipment.exteriorColours);
                }
                return colourAcc;
            }, []);

            if (
                // If the accumulator is empty, return current packColours this indicates a first positive match
                !acc.length ||
                // If the current pack includes another one and has pack colours defined, it gets priority
                (packColours.length && pack.includePacks.length)
            ) {
                return packColours;
            }

            return acc;
        }, []);
    }

    /**
     * Get upholsteries part of selected packs in the basket.
     */
    static getBasketPackUpholsteries(
        packs: PackType[],
        includeAccessoryUpholsteries: boolean = false,
    ): (UpholsteryType | ExtraType)[] {
        let packWithIncludesAndUpholsteriesFound = false;
        let accessoryUpholsteries: ExtraType[] = [];
        let upholsteries: UpholsteryType[] = [];
        packs.forEach((pack) => {
            // Accessory upholsteries are regular equipment items, so these should be concatenated throughout all the packs which have any
            if (includeAccessoryUpholsteries && pack.visualItems.equipmentUpholsteries) {
                accessoryUpholsteries = accessoryUpholsteries.concat(pack.visualItems.equipmentUpholsteries);
            }
            // As long as no pack with includes and upholsteries is found just run through the list of packs and get the last one with packUpholsteries defined
            if (!packWithIncludesAndUpholsteriesFound && pack.visualItems.upholsteries.length > 0) {
                upholsteries = [...pack.visualItems.upholsteries];
            }
            // As soon as we find a pack with pack upholsteries and it has include rules defined, treat it with priority over everything else
            if (upholsteries.length && pack.includePacks.length) {
                packWithIncludesAndUpholsteriesFound = true;
            }
        });
        return [...accessoryUpholsteries, ...upholsteries].sort((a, b) => a.index - b.index);
    }

    /**
     * Get the equipment part of the selected pack
     */
    static getPackEquipment(packs: PackType[]): (ExtraType | WheelType)[] {
        return (
            packs
                // Create Array of all equipment
                .reduce((accumulator: (ExtraType | WheelType)[], pack) => accumulator.concat(pack.equipment), [])
                // Filter out all duplicates
                .reduce((accumulator: (ExtraType | WheelType)[], equipment) => {
                    if (!accumulator.find((eq: WheelType | ExtraType) => eq.id === equipment.id)) {
                        accumulator.push(equipment);
                    }
                    return accumulator;
                }, [])
        );
    }

    /**
     * Get the extras (optional and standard equipment) from the packs
     */
    static getExtrasFromPacks(packs: PackType[], standard: boolean = true, optional: boolean = true): ExtraType[] {
        return (
            packs
                // Create Array of all extras
                .reduce((accumulator: ExtraType[], pack) => {
                    if (standard) accumulator = accumulator.concat(pack.visualItems.standard);
                    if (optional) accumulator = accumulator.concat(pack.visualItems.optional);
                    return accumulator;
                }, [])
                // Filter out all duplicates
                .reduce((accumulator: ExtraType[], extra) => {
                    if (!accumulator.find((ex: ExtraType) => ex.id === extra.id)) {
                        accumulator.push(extra);
                    }
                    return accumulator;
                }, [])
        );
    }

    /**
     * Find a given equipment in an array of packs. Returns the packs its found in as well as the equipment item.
     */
    static findPackEquipment(
        equipment: ExtraType,
        packs: PackType[],
        isChildOption: boolean = false,
    ): { packs: PackType[] | null; equipment: ExtraType } | null {
        const filteredPacks = packs.filter((pack) => {
            return pack.equipment.some((packEq: WheelType | ExtraType) => {
                if (isChildOption) {
                    return packEq.isWheel
                        ? false
                        : (packEq as ExtraType).options.find((option) => option.id === equipment.id);
                }
                return packEq.id === equipment.id;
            });
        });

        return filteredPacks.length ? { equipment, packs: filteredPacks } : null;
    }

    static getWheelTyres(wheel: WheelType, tyres: TyreType[]): TyreType[] {
        return this.getTyresIncludedByWheel(wheel, tyres);
    }

    static getPackConflictsBetweenPacks(pack: PackType, allPacks: PackType[]): PackType[] {
        // See if any of the included packs exclude eachother, if so return a list of the packs which exclude eachother
        const conflictingPacks: PackType[] = pack.includePacks
            .reduce((acc: string[], packId) => {
                const includedPack = find(packId, allPacks);
                if (includedPack) {
                    // Check the excludes to see if it is present in the pack includes
                    includedPack.excludePacks.forEach((excludedPackId) => {
                        if (pack.includePacks.includes(excludedPackId) && !acc.includes(excludedPackId)) {
                            acc.push(excludedPackId);
                        }
                    });
                }
                return acc;
            }, [])
            // Map the conflicts to their respective objects
            .map((packId) => find(packId, allPacks)!)
            // Filter out undefined ones
            .filter(Boolean)
            // Sort based on index
            .sort((a, b) => a.index - b.index);

        return conflictingPacks;
    }

    static packHasConflictsBetweenPacks(
        pack: PackType,
        allPacks: PackType[],
        selectedPacks: PackType[],
    ): PackType[] | false {
        const conflictingPacks = this.getPackConflictsBetweenPacks(pack, allPacks);
        // Check if one of the conflicting packs is already selected, if so, the conflict is already resolved
        const alreadyResolved = conflictingPacks.reduce((acc: boolean, item) => {
            if (find(item.id, selectedPacks)) {
                return true;
            }
            return acc || false;
        }, false);

        return conflictingPacks.length && !alreadyResolved ? conflictingPacks : false;
    }

    /**
     * Checks for conflicts between extras.
     */
    static extraHasConflicts(extra: ExtraType, extras: ExtraType[], selectedExtras: ExtraType[]): ExtraType[] | false {
        const includedExtras = this.getIncludedExtras(extra, extras);

        // are there any conflicts between the included extras.
        const conflictingExtras = includedExtras.filter(
            (includedExtra) =>
                // Is excluded by another included item
                includedExtras
                    // Filter out the extra itself
                    .filter((a) => a.id !== includedExtra.id)
                    // filter out extras that excludes this extra
                    .filter((otherExtra) => otherExtra.excluded.indexOf(includedExtra.id) !== -1).length > 0,
        );

        const unresolvedConflictingBatches = conflictingExtras
            // Split conflicting extras into groups according to their excludes.
            .reduce((batches: ExtraType[][], conflict: ExtraType) => {
                const currentBatch = batches.find(
                    (batch) =>
                        !!batch.find((otherConflict: ExtraType) => conflict.excluded.indexOf(otherConflict.id) !== -1),
                );
                if (currentBatch) {
                    currentBatch.push(conflict);
                } else {
                    batches.push([conflict]);
                }
                return batches;
            }, [])
            // Filter batches that already have a selected item.
            .filter(
                (batch) =>
                    !batch.find(
                        (currentExtra: ExtraType) =>
                            !!selectedExtras.find((selectedExtra) => currentExtra.id === selectedExtra.id),
                    ),
            );

        if (unresolvedConflictingBatches.length === 0) {
            return false;
        }
        return unresolvedConflictingBatches.shift()!;
    }

    static getNonConflictingIncludedExtras(
        extra: ExtraType,
        allExtras: ExtraType[],
        { extras, colour, upholstery, primaryAccentColour, secondaryAccentColour }: BasketType,
    ): ExtraType[] {
        const includedExtras = this.getIncludedExtras(extra, allExtras);
        return (
            includedExtras
                // filter out selected items
                .filter((includedExtra) => !extras.find((selectedExtra) => includedExtra.id === selectedExtra.id))
                // Filter out conflicting items
                .filter(
                    (includedExtra) =>
                        // is not excluded by another included extra
                        includedExtras
                            // don't compare with itself
                            .filter((incExtra) => incExtra.id !== includedExtra.id)
                            // filter out extras that excludes this extra
                            .filter((incExtra) => incExtra.excluded.indexOf(includedExtra.id) !== -1).length === 0,
                )
                // Filter out unavailable color items, this should never be the case though since than it points to a config issue!
                .filter((includedExtra) => {
                    const isAvailable =
                        this.isAvailable(includedExtra, colour, upholstery).available &&
                        this.validatesForColourMode(includedExtra, {
                            colour,
                            primaryAccentColour,
                            secondaryAccentColour,
                        });
                    if (!isAvailable)
                        Debug.error(
                            "Including an optional item which is not available for the current colour/upholstery combination!",
                            [extra, includedExtra],
                        );
                    return isAvailable;
                })
        );
    }

    /**
     * Returns a list of includes from the provided extra.
     */
    static getIncludedExtras(item: ExtraType | PackType, extras: ExtraType[]): ExtraType[] {
        if (!item) return [];

        // For childoptions we only check includes to other extras, not to other childoption extras.
        let includedExtras: string[] = item.included;
        const childOption = item.objectType === "extra" ? CarDataConsolidator.getSelectedExtraOption(item) : null;
        if (childOption) includedExtras = includedExtras.concat(childOption.included);

        // Same applies for set-items, only resolve include rules towards other extras, not other set-items
        if (item.objectType === "extra" && item.setItems.length > 0) {
            includedExtras = item.setItems.reduce((acc, setItem) => {
                return acc.concat(setItem.included);
            }, includedExtras);
        }

        // Check include rules from this extra towards other set-items available on other extras
        return includedExtras
            .map((inclExtra) => {
                return extras.find((ex) => {
                    return (!ex.standard && inclExtra === ex.id) || find(inclExtra, ex.setItems);
                })!;
            })
            .filter(Boolean);
    }

    /**
     * Returns a list of packs which have includes towards the provided item (similar method as to resolve getExtrasIncludedBy but less complex
     */
    static getPacksIncludedBy(item: ExtraType | PackType, packs: PackType[]): PackType[] {
        return packs.filter((selectedPack) => {
            return selectedPack.includePacks.includes(item.id) || selectedPack.included.includes(item.id);
        });
    }

    static getTyresIncludedByWheel(wheel: WheelType, tyres: TyreType[]): TyreType[] {
        return tyres.filter((selectedTyre) => {
            return wheel.included.includes(selectedTyre.id) || (wheel.standard && selectedTyre.standard);
        });
    }

    /**
     * Returns a list of extras which have includes towards the provided item
     */
    static getExtrasIncludedBy(
        item: ExtraType | PackType,
        extras: ExtraType[],
        childOption: ExtraType | null = null,
    ): ExtraType[] {
        return extras.filter((selectedExtra) => {
            const selectedExtraIncludesChildOption = childOption && selectedExtra.included.includes(childOption.id);
            const selectedExtraIncludesExtra = selectedExtra.included.includes(item.id);
            const selectedExtraIncludesSetItem =
                item.objectType === "extra"
                    ? selectedExtra.included.find((includeExtra) => {
                          return find(includeExtra, item.setItems);
                      })
                    : false;
            const selectedExtraSetItemIncludesExtra = selectedExtra.setItems.find((setItem) => {
                return setItem.included.includes(item.id);
            });
            const selectedExtraPackIncludes = selectedExtra.includePacks.includes(item.id);
            return (
                selectedExtraIncludesExtra ||
                selectedExtraPackIncludes ||
                selectedExtraIncludesChildOption ||
                selectedExtraIncludesSetItem ||
                selectedExtraSetItemIncludesExtra
            );
        });
    }

    static getExcludedExtras(
        item: ExtraType | PackType,
        selectedExtras: ExtraType[],
        childOption: ExtraType | null = null,
    ): ExtraType[] {
        // If the item is a pack, it does not have setItems
        const setItemExcludes =
            item.objectType === "extra"
                ? item.setItems.reduce((acc: string[], setItem) => {
                      return acc.concat(setItem.excluded);
                  }, [])
                : [];
        return selectedExtras.filter((selectedExtra) => {
            const hasChildOptionExcludes = childOption ? childOption.excluded.indexOf(selectedExtra.id) > -1 : false;
            const hasExtraExcludes = item.excluded.indexOf(selectedExtra.id) > -1;
            const hasSetItemExcludes = setItemExcludes.indexOf(selectedExtra.id) > -1;
            const hasSetItemExcludedBySelectedExtra = selectedExtra.setItems.find((setItem) => {
                return setItem.excluded.indexOf(item.id) > -1;
            });
            const hasExcludedSelectedSetItemExtra = selectedExtra.setItems.length
                ? item.excluded.find((exclExtra) => {
                      return find(exclExtra, selectedExtra.setItems);
                  })
                : false;
            return (
                hasChildOptionExcludes ||
                hasExtraExcludes ||
                hasSetItemExcludes ||
                hasSetItemExcludedBySelectedExtra ||
                hasExcludedSelectedSetItemExtra
            );
        });
    }

    /**
     * Method which checks if the conflict is solved already,
     * a conflict is considered solved when the selectedExtras contain exactly one of the conflicts.
     * @param conflicts
     * @param selectedExtraIds
     * @returns {boolean}
     */
    // TODO Conflictype?
    static isPackConflictSolved(conflicts: { id: string }[], selectedExtraIds: string[]): boolean {
        const activeConflicts = conflicts
            .map((conflict) => conflict.id)
            .filter((conflictId) => selectedExtraIds.includes(conflictId));

        // If there is only one item left
        return activeConflicts.length === 1;
    }

    /**
     * Determine if a pack extra has conflicts
     */
    static determinePackExtraConflicts(
        extra: ExtraType,
        equipment: ExtraType[],
        packEquipment: ExtraType[],
        selectedExtraIds: string[],
        handledExtras: string[] = [],
    ): false | ExtraType[] {
        if (handledExtras.indexOf(extra.id) > -1) {
            return false;
        }
        handledExtras.push(extra.id);

        if (extra.excluded.length > 0) {
            // Get extras excluded by this extra.
            const excludedByIncludedExtra = mapIds(extra.excluded, equipment);
            if (excludedByIncludedExtra.length > 0) {
                const conflict = [...excludedByIncludedExtra, extra];
                if (!this.isPackConflictSolved(conflict, selectedExtraIds)) {
                    return conflict;
                }
            }
        }

        // TODO Investigate by Bart, the code below used to be uncommented but it would return false anyway.
        // if (extra.type !== "upholsterytype") {
        return false;
        // }
        // return mapIds(extra.included, packEquipment).reduce(
        //     (conflict, includedExtra) =>
        //         conflict ||
        //         this.determinePackExtraConflicts(
        //             includedExtra,
        //             packEquipment,
        //             packEquipment,
        //             selectedExtraIds,
        //             handledExtras,
        //         ),
        //     false,
        // );
    }

    /**
     * Checks if a pack has conflicts between standard pack equipment items
     */
    static packHasStandardConflicts(
        standardEquipment: ExtraType[],
        optionalEquipment: ExtraType[],
        equipment: ExtraType[],
        selectedExtras: ExtraType[],
    ): false | ExtraType[] {
        const selectedExtraIds = selectedExtras.map((extra) => extra.id);

        // Standard equipment items excluding each other are optionalEquipment, but should be added to standard
        // to correctly determine conflicts. See OR-201.
        standardEquipment = standardEquipment.concat(optionalEquipment.filter((eq) => eq.standard));

        // Loop through standard pack extra include/excludes and check if we have any conflicts.
        // If standard items exclude each other the user has to choose between one of the extras.
        return standardEquipment.reduce(
            (conflict: false | ExtraType[], packExtra) =>
                conflict || this.determinePackExtraConflicts(packExtra, standardEquipment, equipment, selectedExtraIds),
            false,
        );
    }

    /**
     * Filter standard equipment specifications.
     */
    static filterStandardEquipmentSpecs(
        basket: BasketType,
        items: ItemsType,
        standardEquipment: ExtraType[],
    ): ExtraType[] {
        const { packs, packExtras, extras } = basket;

        // Get excluded extras from equipped packExtras and default pack extras.
        let excludedExtras = packExtras.reduce((acc: string[], packExtra) => acc.concat(packExtra.excluded), []);

        // Also loop through the equipped standard packitems.
        excludedExtras = packs.reduce((acc, pack) => {
            pack.equipment
                .filter((extra) => extra.standard)
                .forEach((extra) => {
                    acc = acc.concat(extra.excluded);
                });
            return acc;
        }, excludedExtras);

        excludedExtras = extras.reduce((acc, extra) => acc.concat(extra.excluded), excludedExtras);

        // Create array of all full object extras available
        const itemsPackExtras: ExtraType[] = items.packs.reduce(
            // Force pack equipment to an extra type.
            // Currently assuming that standard equipment type will always be an extra and not a pack wheel extra...
            (acc: ExtraType[], pack) => acc.concat(pack.equipment as ExtraType[]),
            [],
        );
        const allExtras = items.extras.concat(itemsPackExtras, items.wheelExtras).concat(standardEquipment);

        const dedupeExtras: string[] = [];
        const filteredExtras: ExtraType[] = excludedExtras
            // Cast to non null but make sure to filter for undefined!
            .map((extraId) => find(extraId, allExtras)!)
            .filter(Boolean)
            // Filter for standard extras.
            .filter((extra) => extra.standard)
            // Remove duplicates.
            .filter((extra) => {
                if (!dedupeExtras.includes(extra.id)) {
                    dedupeExtras.push(extra.id);
                    return true;
                }
                return false;
            });

        return standardEquipment.filter((extra) => !find(extra.id, filteredExtras));
    }

    static getStandardColourDependantPackEquipment(packs: PackType[]): ExtraType[] {
        return packs.reduce(
            (equipment: ExtraType[], pack) =>
                equipment.concat(
                    pack.extended && pack.visualItems
                        ? pack.visualItems.standard.filter(
                              (extra) => extra.options.length > 0 || extra.colouringMode !== 0,
                          )
                        : [],
                ),
            [],
        );
    }

    static getAllRelevantBasketExtras(basket: BasketType, items: ItemsType): ExtraType[] {
        const { extras, filterCarOptions } = items;

        const combinedExtras = [
            ...basket.extras,
            ...basket.packExtras,
            ...CarDataConsolidator.getStandardColourDependantPackEquipment(basket.packs),
            // Optionally add filterCarOptions.
            ...(filterCarOptions || []),
        ];

        // Optional included standard pack extras need to be added only for ccis and config saver.
        return this.getExtrasBasedOnColour(
            basket,
            this.mapExtrasBasedOnAccentColour(
                [
                    ...combinedExtras,
                    ...this.getOptionalIncludedStandardPackExtras(basket.packs, extras, combinedExtras),
                ],
                basket.colour,
                basket.primaryAccentColour,
                basket.secondaryAccentColour,
            ),
        );
    }

    /**
     *  Get the optional equipment items which are included by standard pack equipment items
     *  @param basketPacks - Selected packs.
     *  @param extras - All extras from item reducer in state.
     *  @param filterExtras - Array of extras which do not need to be returned.
     */
    static getOptionalIncludedStandardPackExtras(
        basketPacks: PackType[],
        extras: ExtraType[],
        filterExtras: ExtraType[] = [],
    ): ExtraType[] {
        const nonStandardExtras = extras.filter((extra) => !extra.standard);
        const packEquipment = this.getPackEquipment(basketPacks);
        return basketPacks.reduce((optionalIncludedStandardPackExtras: ExtraType[], pack) => {
            pack.equipment
                // Get standard equipment of the pack, but not the ones containing options (= childoptions, used for resolving aygo pack selection)
                .filter(
                    (extra) =>
                        extra.standard &&
                        (typeof (extra as ExtraType).options === "undefined" ||
                            (extra as ExtraType).options.length === 0),
                )
                // Loop through standard equipment, check if it includes optional equipment items.
                .forEach((extra, index, standardExtras) => {
                    extra.included
                        // Filter out includes which are already part of a selected pack's equipment
                        .filter((includedExtraId) => !find(includedExtraId, packEquipment))
                        // Get the optional equipment items included by this equipment item.
                        .map((includedExtraId) => find(includedExtraId, nonStandardExtras)!)
                        // Filter empty values.
                        .filter(Boolean)
                        .forEach((optionalInclude) => {
                            // Check if this optional include isn't excluded by other standard extra's of current pack.
                            const excluded = standardExtras.find((standardExtra) =>
                                standardExtra.excluded.includes(optionalInclude.id),
                            );

                            // Not excluded by standard extra's, add to results.
                            if (!excluded && !find(optionalInclude.id, filterExtras)) {
                                optionalIncludedStandardPackExtras.push(optionalInclude);
                            }
                        });
                });

            return optionalIncludedStandardPackExtras;
        }, []);
    }

    static getDefaultBodyColourCombination(pack: PackType): {
        colourID: string;
        primaryAccentColour: AccentColourType;
        secondaryAccentColour: AccentColourType | null;
    } | null {
        if (!pack.extended || !pack.extended.accentColours) {
            return null;
        }

        const { accentColours } = pack.extended;
        const oKeys = Object.keys(accentColours);

        const colourID = oKeys[0];
        if (!colourID) return null;
        const primaryAccentColour = accentColours[colourID][0];
        const secondaryAccentColour = primaryAccentColour.accentColours && primaryAccentColour.accentColours[0];

        const defaultBodyColourCombination = {
            colourID,
            primaryAccentColour,
            secondaryAccentColour,
        };

        return oKeys.reduce((bcc, key) => {
            const match = accentColours[key].find((pac) => pac.default);
            if (match) {
                return {
                    colourID: key,
                    primaryAccentColour: match,
                    secondaryAccentColour: match.accentColours && match.accentColours[0],
                };
            }
            return bcc;
        }, defaultBodyColourCombination);
    }

    static getPackPrimaryAccentColours(colourID: string, pack: PackType): AccentColourType[] {
        if (!pack.extended || !pack.extended.accentColours[colourID]) {
            return [];
        }
        const accentColours = [...pack.extended.accentColours[colourID]];

        const items = pack.visualItems ? pack.visualItems.standard.filter((extra) => extra.options.length > 0) : [];

        return accentColours.filter((accentColour) =>
            items.find((item) =>
                item.options.find(
                    (option) =>
                        [2, 3, 6, 7].includes(option.colouringMode) &&
                        option.colour &&
                        option.colour.id === accentColour.id,
                ),
            ),
        );
    }

    static getSecondaryAccentColours(basket: BasketType, pack: PackType): AccentColourType[] {
        const { primaryAccentColour, colour } = basket;
        if (!colour || !primaryAccentColour || !pack.extended || !pack.extended.accentColours[colour.id]) {
            return [];
        }
        const packPrimaryAccentColour = find(primaryAccentColour.id, pack.extended.accentColours[colour.id]);
        if (!packPrimaryAccentColour || !packPrimaryAccentColour.accentColours) {
            return [];
        }

        const items = this.getExtrasBasedOnColour(
            basket,
            (pack.visualItems ? pack.visualItems.optional : []).filter((extra) => extra.options.length > 0),
        );

        return packPrimaryAccentColour.accentColours
            ? packPrimaryAccentColour.accentColours.filter((accentColour) =>
                  items.find((item) =>
                      item.options.find(
                          (option) =>
                              [4, 5, 6, 7].includes(option.colouringMode) &&
                              option.colour &&
                              option.colour.id === accentColour.id,
                      ),
                  ),
              )
            : [];
    }

    /**
     * This method resolves colour dependencies (& availability!) on each extra. This is also used to resolve wheel conflicts.
     * It does not take into account ChildOptions of equipment items, this is handled in the mapExtrasBasedOnAccentColour function.
     */
    static getExtrasBasedOnColour(
        colourConfig: {
            colour: { id: string; [x: string]: any };
            primaryAccentColour: AccentColourType | null;
            secondaryAccentColour: AccentColourType | null;
        },
        equipment: ExtraType[],
    ): ExtraType[] {
        return equipment.filter((extra) => this.validatesForColourMode(extra, colourConfig));
    }

    /**
     * Method which checks based on the given extra it's colouringMode against the body colour, primary and/or secondary accent colors
     */
    static validatesForColourMode(
        extra: ExtraType,
        {
            colour,
            primaryAccentColour,
            secondaryAccentColour,
        }: {
            colour: { id: string; [x: string]: any };
            primaryAccentColour: AccentColourType | null;
            secondaryAccentColour: AccentColourType | null;
        },
    ): boolean {
        return !!(
            (!extra.availability ||
                extra.availability.colours.length === 0 ||
                extra.availability.colours.includes(colour.id)) &&
            (!extra.colouringMode ||
                extra.colouringMode === CM_NONE ||
                (extra.colouringMode === CM_BODY_COLOUR && extra.colour && extra.colour.id === colour.id) ||
                (extra.colouringMode === CM_PRIMARY_ACCENT_COLOUR &&
                    primaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === primaryAccentColour.id) ||
                (extra.colouringMode === CM_BODY_COLOUR + CM_PRIMARY_ACCENT_COLOUR &&
                    primaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === primaryAccentColour.id &&
                    extra.colour.id === colour.id) ||
                (extra.colouringMode === CM_SECONDARY_ACCENT_COLOUR &&
                    secondaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === secondaryAccentColour.id) ||
                (extra.colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_BODY_COLOUR &&
                    secondaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === secondaryAccentColour.id &&
                    extra.colour.id === colour.id) ||
                (extra.colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_PRIMARY_ACCENT_COLOUR &&
                    secondaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === secondaryAccentColour.id &&
                    primaryAccentColour &&
                    extra.colour.id === primaryAccentColour.id) ||
                (extra.colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_PRIMARY_ACCENT_COLOUR + CM_BODY_COLOUR &&
                    secondaryAccentColour &&
                    extra.colour &&
                    extra.colour.id === secondaryAccentColour.id &&
                    primaryAccentColour &&
                    extra.colour.id === primaryAccentColour.id &&
                    extra.colour.id === colour.id))
        );
    }

    /**
     * Used to create the basket extra list (through GetAllRelevantBasketExtras)
     * This method checks the ChildOptions (= options) of the Equipment items (= extras) if none of the childoptions validate and the onlyCheckColouringMode === -1, it returns the extra
     */
    static mapExtrasBasedOnAccentColour(
        // TODO In some cases equipment is both extra and upholstery.
        equipment: ExtraType[],
        // Colour only requires an id because in some cases there is only a colour id available.
        colour: { id: string; [x: string]: any },
        primaryColour: AccentColourType | null,
        secondaryColour: AccentColourType | null,
        onlyCheckColouringMode: number = -1,
    ): ExtraType[] {
        if (!equipment) {
            return [];
        }
        return equipment
            .map((extra) => {
                if (extra.options && extra.options.length > 0) {
                    // TODO This does weird stuff, investigate.
                    const colouringMode: number | false = extra.options.reduce(
                        (cm: number | false, option) => cm || option.colouringMode,
                        false,
                    );
                    // This variable only returns the options which validate for the given onlyCheckColouringMode variable
                    const checkColour = onlyCheckColouringMode === -1 || onlyCheckColouringMode === colouringMode;

                    /* eslint-disable max-len */
                    if (colouringMode === CM_BODY_COLOUR && checkColour) {
                        return extra.options.find((option) => option.colour && option.colour.id === colour.id);
                    } else if (colouringMode === CM_PRIMARY_ACCENT_COLOUR && primaryColour && checkColour) {
                        return extra.options.find(
                            (option) => option.colour && primaryColour && option.colour.id === primaryColour.id,
                        );
                    } else if (
                        colouringMode === CM_BODY_COLOUR + CM_PRIMARY_ACCENT_COLOUR &&
                        primaryColour &&
                        checkColour
                    ) {
                        return extra.options.find(
                            (option) =>
                                option.colour &&
                                primaryColour &&
                                option.colour.id === primaryColour.id &&
                                extra.colour &&
                                extra.colour.id === colour.id,
                        );
                    } else if (colouringMode === CM_SECONDARY_ACCENT_COLOUR && secondaryColour && checkColour) {
                        return extra.options.find(
                            (option) => option.colour && secondaryColour && option.colour.id === secondaryColour.id,
                        );
                    } else if (
                        colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_BODY_COLOUR &&
                        secondaryColour &&
                        checkColour
                    ) {
                        return extra.options.find(
                            (option) =>
                                option.colour &&
                                secondaryColour &&
                                option.colour.id === secondaryColour.id &&
                                extra.colour &&
                                extra.colour.id === colour.id,
                        );
                    } else if (
                        colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_PRIMARY_ACCENT_COLOUR &&
                        primaryColour &&
                        secondaryColour &&
                        checkColour
                    ) {
                        return extra.options.find(
                            (option) =>
                                option.colour &&
                                primaryColour &&
                                secondaryColour &&
                                option.colour.id === secondaryColour.id &&
                                option.colour.id === primaryColour.id,
                        );
                    } else if (
                        colouringMode === CM_SECONDARY_ACCENT_COLOUR + CM_PRIMARY_ACCENT_COLOUR + CM_BODY_COLOUR &&
                        primaryColour &&
                        secondaryColour &&
                        checkColour
                    ) {
                        return extra.options.find(
                            (option) =>
                                option.colour &&
                                primaryColour &&
                                secondaryColour &&
                                option.colour.id === secondaryColour.id &&
                                option.colour.id === primaryColour.id &&
                                option.colour.id === colour.id,
                        );
                    }
                    /* eslint-enable */

                    if (onlyCheckColouringMode > -1) return null;
                }
                return onlyCheckColouringMode === -1 ? extra : null;
            })
            .filter(Boolean) as ExtraType[];
    }

    static getPrimaryAccentColours(colourID: string, pack: PackType): AccentColourType[] {
        return this.getPackPrimaryAccentColours(colourID, pack);
    }

    /**
     * Method which resolves the primary/secondary accent colour based on a given array of extras
     * @param extras:Array of extras
     * @param packs:Array of packs to search in their equipment their childoptions for a match
     * @param primaryAccentColor:Object the primaryAccentColor object resolved from within the corresponding pack; when provided it will search for the secondary accent color wihin the primaryAccentColor based on the given extras
     */
    static getAccentColourFromExtras(
        extras: ExtraType[],
        packs: PackType[],
        primaryAccentColor?: AccentColourType,
    ): AccentColourType | null {
        // Loop through the given packs
        return extras.reduce((accumulator: AccentColourType | null, extra: ExtraType) => {
            // Return accumulator when already found.
            if (accumulator) return null;
            // Else search for it
            const match = this.findPackEquipment(extra, packs, true);
            if (match && match.packs && match.packs.length) {
                // Take the first pack; accent colours are shared among packs so doesn't really matter which one you take to get the accent colour from
                const pack = match.packs[0];
                // If the primary accent color is defined, search for the secondary accent color, else search for the primary.
                const validColouringModes = primaryAccentColor ? [4, 5, 6, 7] : [2, 3, 6, 7];
                // Find the colouringmode of the matched equipment
                if (validColouringModes.find((cm) => cm === match.equipment.colouringMode)) {
                    if (primaryAccentColor) {
                        if (match.equipment.colour && primaryAccentColor.accentColours) {
                            return find(match.equipment.colour.id, primaryAccentColor.accentColours) || null;
                        }
                    } else if (pack.extended) {
                        return Object.keys(pack.extended.accentColours).reduce(
                            (colours: AccentColourType | null, key: string) => {
                                if (
                                    !colours &&
                                    match.equipment.colour &&
                                    pack.extended &&
                                    pack.extended.accentColours
                                ) {
                                    return find(match.equipment.colour.id, pack.extended.accentColours[key])! || null;
                                }
                                return colours;
                            },
                            null,
                        );
                    }
                }
            }
            return accumulator;
        }, null);
    }

    /**
     * Get ids of all the items which are not selected in the basket.
     */
    static getUnselectedItemIds(basket: BasketType, items: ItemsType): string[] {
        const { wheel, extras, packs } = basket;

        // TODO Should we take wheelExtras/childExtras/equipmentUpholsteries into account?
        return ([] as unknown[])
            .concat(items.wheels.filter((itemWheel) => wheel.id !== itemWheel.id))
            .concat(items.extras.filter((itemExtra) => !extras.find((extra) => itemExtra.id === extra.id)))
            .concat(items.packs.filter((itemPack) => !packs.find((pack) => itemPack.id === pack.id)))
            .map((unselectedItem: any) => unselectedItem.id) as string[];
    }

    static getSelectedExtraOption(extra: ExtraType): ExtraType | null {
        return extra.selectedOptionId ? extra.options.find((option) => option.id === extra.selectedOptionId)! : null;
    }

    /**
     * Method which checks if an extra should be hidden or not based on specific criteria
     */
    static hideExtra(extra: ExtraType, settings: CommonSettingsType): boolean {
        // selectedEquipment is used in context of a proace filter configuration where we don't want to show selectedEquipment because else the user can choose to deselect equipment which are essentially part of that filtering process
        return (
            extra.isHidden ||
            extra.isUpholstery ||
            (settings.query.selectedEquipment ? settings.query.selectedEquipment.indexOf(extra.shortId) > -1 : false)
        );
    }

    static getIncludedServiceProducts(
        serviceProduct: ServiceProductType,
        allServiceProducts: ServiceProductType[],
    ): ServiceProductType[] {
        return allServiceProducts.filter((product) =>
            serviceProduct.includeService.find((service) => service.shortId === product.shortId),
        );
    }

    static getExcludedServiceProducts(
        serviceProduct: ServiceProductType,
        allServiceProducts: ServiceProductType[],
    ): ServiceProductType[] {
        return allServiceProducts.filter((product) =>
            serviceProduct.excludeService.find((service) => service.shortId === product.shortId),
        );
    }

    static getServiceProductConflicts(
        serviceProduct: ServiceProductType,
        allServiceProducts: ServiceProductType[],
        selectedServiceProducts: ServiceProductType[],
        userSelected?: boolean,
    ): ServiceProductType[] {
        const includedServiceProducts = this.getIncludedServiceProducts(serviceProduct, allServiceProducts);
        const excludedServiceProducts = this.getExcludedServiceProducts(serviceProduct, allServiceProducts);

        const conflictingServices = userSelected
            ? []
            : allServiceProducts.filter((product) =>
                  excludedServiceProducts.find((excludedService) => excludedService.shortId === product.shortId),
              );

        // Check to see if there are conflicts within the serviceProducts
        // Check if the included services have an excluded service.
        includedServiceProducts.forEach((includedProduct) => {
            const includedProductConflicts = this.getServiceProductConflicts(
                includedProduct,
                allServiceProducts,
                selectedServiceProducts,
                false,
            );

            if (includedProductConflicts.length > 0) {
                conflictingServices.push(...includedProductConflicts);
            }
        });

        // Check to see if there are conflicts within the serviceProducts
        return conflictingServices;
    }
}
