import { SyntheticEvent } from "react";
import { showModal } from "../../../common-deprecated/redux/actions/ModalActions";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { getUscUrl } from "../../shared-logic/utils/uscUtils";
import { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { CarFilterDispatchType } from "../redux/store";
import { UscContext, UsedCarResultType } from "../../shared-logic/types/UscCommonTypes";
import { getLocalDealerFormData, getUscVehicleUrlInfoFromResult } from "./helpers";
import { getCarFilterLabel } from "./constants/labels";
import { MODAL_USC_FORM } from "../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { DfVehicleStatus } from "../../shared-logic/utils/dfConstants";
import { openMaterialBox } from "../../../common-deprecated/utils/postalUtils";
import type { ColorType } from "../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export enum CarFilterCtaIcon {
    Dealer = "dealer",
    Phone = "phone",
}

export enum CarFilterCtaTypeEnum {
    ContactDealer = "contactDealer",
    Next = "next",
}

export type CarFilterCtaType = {
    label: string;
    icon?: CarFilterCtaIcon;
    onClick?: (event: React.SyntheticEvent) => void;
    href?: string;
    type?: CarFilterCtaTypeEnum;
};

/**
 * Opens the Contact Dealer form
 */
export const openContactDealerForm = (
    vehicle: UsedCarResultType,
    detailPageUrl: string,
    context: "used" | "stock",
    commonSettings: CommonSettingsType,
    dispatch: CarFilterDispatchType,
    modalTitle: string,
): void => {
    const { dealer } = vehicle;

    const vehicleUrl = getUscUrl(commonSettings, detailPageUrl, context, getUscVehicleUrlInfoFromResult(vehicle));

    // modalLogic will attach additional vehicle/dealer/finance/... information to this event so we dont have to propagate it.
    dispatch(
        showModal({
            type: MODAL_USC_FORM,
            vehicleForSaleId: vehicle.id,
            uscContext: context,
            modalTitle,
            externalData: {
                // This will always be "null", as on the Car Filter the user has not had the
                // option to open the Finance/Insurance calculators
                externalPriceInfo: null,
                vehicleUrl,
                onCarFilter: true,
            },
            dealer: dealer || undefined,
            vehicle,
        }),
    );
};

/**
 * Returns a Contact Dealer CTA to be used on the Car Filter (result cards)
 */
export const getContactDealerCta = (
    vehicle: UsedCarResultType,
    detailPageUrl: string,
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    context: "used" | "stock", // retyped for getUscUrl
    dispatch: CarFilterDispatchType,
): CarFilterCtaType | null => {
    // Don't show the CTA if the vehicle is sold, as the user should not be able to contact the dealer
    if (vehicle?.vehicleStatus.code === DfVehicleStatus.Sold) return null;

    const contactLabel = getCarFilterLabel({ carFilterSettings, commonSettings }, "carFilterContactDealerCta");
    const localContactDealerFormUrl =
        context === "used"
            ? carFilterSettings.usedCarsLocalContactDealerFormUrl
            : carFilterSettings.stockCarsLocalContactDealerFormUrl;

    let contactDealerclick: ((event: SyntheticEvent) => void) | null = null;

    if (localContactDealerFormUrl && !commonSettings.query.disableLocalContactForm) {
        contactDealerclick = () => {
            let url = localContactDealerFormUrl;

            if (vehicle) {
                // Make sure getUscUrl is called within the function handler as it parses T1 window object data which tends to not be activated during init.
                const vehicleUrl = getUscUrl(
                    commonSettings,
                    detailPageUrl,
                    context,
                    getUscVehicleUrlInfoFromResult(vehicle),
                );

                // Get the local form data and parse into url parameters
                const localFormData = getLocalDealerFormData(vehicle, vehicleUrl);

                const urlParams = (Object.keys(localFormData) as (keyof ReturnType<typeof getLocalDealerFormData>)[])
                    .filter((key) => !!localFormData[key])
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    .map((key) => `${key}=${localFormData[key]}`)
                    .join("&");
                url += `?${encodeURI(urlParams)}`;
            }

            // Open into an iframe, as this should be edge case scenario keep the config here for now.
            // Height/width is based on modal dimensions that are usually used
            openMaterialBox({
                url,
                type: "box-iframe",
                height: window.innerHeight - 125,
                width: Math.min(window.innerWidth, 992),
            });
        };
    } else if (vehicle) {
        contactDealerclick = () => {
            const modalTitle = getCarFilterLabel(
                { carFilterSettings, commonSettings },
                "carFilterContactDealerModalTitle",
            );

            openContactDealerForm(vehicle, detailPageUrl, context, commonSettings, dispatch, modalTitle);
        };
    }

    return contactDealerclick
        ? {
              label: contactLabel,
              onClick: contactDealerclick,
              icon: CarFilterCtaIcon.Dealer,
              type: CarFilterCtaTypeEnum.ContactDealer,
          }
        : null;
};
/**
 * This only applies to Toyota buttons on the Car Filter.
 * UCL uses red primary buttons,
 * SCL uses black primary buttons.
 */
export const getToyotaButtonColor = (uscContext: UscContext): ColorType => {
    return uscContext === UscContext.Used ? "primaryRedHover" : "primaryBlack";
};
