import React, { useState } from "react";
import styled from "styled-components";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import ModalBaseView from "../aem/ModalBaseView";
import * as Button from "../../../styles/toyota/Button";
import * as Flex from "../../../styles/toyota/Flexbox";
import { getAPI } from "../../../settings/utils/commonSettingUtils";
import { ModalCacheClearType } from "../../../utils/modalConstants";
import Debug from "../../../Debug";
import { ClearCacheBodyType, ClearCacheResponseType } from "../../../server/v2/endpoints/ClearCacheEndpoint";
import { useCommonSelector } from "../../../redux/commonStore";
import { ModalViewType } from "../../../types/CommonTypes";
import IconWrapper from "../../../styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import { useCommonLabel } from "../../../utils/commonLabels";

enum PurgeStatus {
    None = 0,
    Clearing = 1,
    Cleared = 2,
}

// Need some minor CSS styling for aligning the status text
const Status = styled.div`
    padding-top: 11px;
`;

// Basic modal with a title and an ok button that closes the modal.
const ClearCacheModal = (props: ModalViewType<ModalCacheClearType>): JSX.Element => {
    const { show, close } = props;
    const country = useCommonSelector((state) => state.commonSettings.country);
    const language = useCommonSelector((state) => state.commonSettings.language);
    const component = useCommonSelector((state) => state.commonSettings.component);
    const apiPath = useCommonSelector((state) => getAPI(state.commonSettings, "clear-cache"));
    const orV2 = useCommonSelector((state) => (state.commonSettings as unknown as { orV2: boolean })?.orV2 || false);
    const resourcePath = useCommonSelector((state) => state.commonSettings.resourcePath);
    const [status, setStatus] = useState<{ code: PurgeStatus; response?: string }>({
        code: PurgeStatus.None,
        response: "",
    });

    const cacheClearButtonHandler = async (): Promise<void> => {
        setStatus({ code: PurgeStatus.Clearing });
        try {
            if (orV2) {
                const body: ClearCacheBodyType = { country, language };
                const clearCache = await fetch(`${resourcePath}/clear-cache`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                });
                const result: ClearCacheResponseType = await clearCache.json();
                setStatus({ code: PurgeStatus.Cleared, response: JSON.stringify(result) });
            } else {
                const fetchResult = await fetch(`${apiPath}/${component}`);
                const result = await fetchResult.text();
                setStatus({ code: PurgeStatus.Cleared, response: result });
            }
        } catch (ex) {
            Debug.error("Failed to clear cache", ex);
        }
    };

    let label;
    switch (status.code) {
        case PurgeStatus.None:
            label = "Press the button to clear the cache";
            break;
        case PurgeStatus.Clearing:
            label = "Clearing cache";
            break;
        case PurgeStatus.Cleared:
            label = `Cache cleared: ${status.response as string}`;
            break;
    }

    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView onClose={close} show={show} size="md">
            <Modal.Header hasBackground>
                <Modal.Title>
                    Clear the cache for {country}-{language}
                </Modal.Title>
                <Modal.Button type="button" aria-label={closeLabel} onClick={close}>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Modal.Body>
                <Flex.Row>
                    <Flex.Col>
                        <Button.Primary onClick={cacheClearButtonHandler}>Clear cache</Button.Primary>
                    </Flex.Col>
                    <Flex.Col>
                        <Status>{label}</Status>
                    </Flex.Col>
                </Flex.Row>
            </Modal.Body>
        </ModalBaseView>
    );
};

export default ClearCacheModal;
