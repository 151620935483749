import React, { useState } from "react";
import { shallowEqual } from "react-redux";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import { VehicleForSaleIdModalType } from "../../../../shared-logic/utils/modalConstants";
import * as Modal from "../../../../../common-deprecated/styles/v2/toyota/globals/Modal";
import ModalBaseView from "../../../../../common-deprecated/components/modals/base/ModalBaseView";
import * as Styles from "./styles/VehicleForSaleIdModalStyles";
import { useCommonSelector } from "../../../../../common-deprecated/redux/commonStore";
import { UscContext } from "../../../../shared-logic/types/UscCommonTypes";
import ButtonSecondary from "../../../../../common-deprecated/components/aem/toyota/ButtonSecondary";
import IconWrapper from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { Icon } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCommonLabel } from "../../../../../common-deprecated/utils/commonLabels";

const generateUrl = (base: string, params: Record<string, string>): string => {
    let url = base;

    const queryParams = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");

    if (queryParams) {
        url += `?${queryParams}`;
    }

    return url;
};

const VehicleForSaleIdModal = ({
    close,
    show,
    modalSettings,
}: ModalViewType<VehicleForSaleIdModalType>): JSX.Element => {
    const { isStandalone, country, language } = useCommonSelector((state) => state.commonSettings, shallowEqual);
    const { detailPageUrl, landingPageUrl } = modalSettings;
    const [vehicleForSaleId, setVehicleForSaleId] = useState<string>("");
    const [desiredComponent, setDesiredComponent] = useState<"carFilter" | "pdp">("pdp");
    const [vehicleType, setVehicleType] = useState<UscContext>(UscContext.Used);

    const closeLabel = useCommonLabel("close");

    const handleSubmit = (): void => {
        const baseUrls = {
            carFilter: isStandalone ? `/${country}/${language}/car-filter` : landingPageUrl,
            pdp: isStandalone ? `/${country}/${language}/used-stock-cars` : detailPageUrl,
        };

        const params = {
            vehicleForSaleId,
            ...(desiredComponent === "pdp" && { uscContext: vehicleType }),
        };

        window.location.href = generateUrl(baseUrls[desiredComponent], params);
    };

    return (
        <ModalBaseView onClose={close} show={show} size="md" disableOutsideClick>
            <Modal.Header hasBackground hasBorder>
                <Modal.Title isCentered>Navigate to a specific vehicle</Modal.Title>
                <Modal.Button type="button" onClick={close} aria-label={closeLabel}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Enter a VehicleForSaleID to either navigate to the PDP, or filter the results to show only the
                    vehicle with the given ID.
                </p>
            </Modal.Body>
            <Modal.Actions>
                <Styles.Wrapper>
                    <Styles.Input
                        type="text"
                        placeholder="VehicleForSaleID"
                        value={vehicleForSaleId}
                        onChange={(e) => setVehicleForSaleId(e.target.value)}
                    />
                    <Styles.ButtonWrapper>
                        <Styles.GroupedButtons>
                            <Styles.RadioWrapper>
                                <Styles.Radio
                                    type="radio"
                                    id="pdp"
                                    name="desiredComponent"
                                    value={desiredComponent}
                                    onChange={() => setDesiredComponent("pdp")}
                                    defaultChecked
                                />
                                <Styles.Label htmlFor="pdp">Navigate to PDP</Styles.Label>
                            </Styles.RadioWrapper>
                            <Styles.RadioWrapper>
                                <Styles.Radio
                                    type="radio"
                                    id="carFilter"
                                    name="desiredComponent"
                                    value={desiredComponent}
                                    onChange={() => setDesiredComponent("carFilter")}
                                />
                                <Styles.Label htmlFor="carFilter">Filter the results</Styles.Label>
                            </Styles.RadioWrapper>
                        </Styles.GroupedButtons>
                        <Styles.GroupedButtons>
                            <Styles.RadioWrapper>
                                <Styles.Radio
                                    type="radio"
                                    id="used"
                                    name="vehicleType"
                                    value={vehicleType}
                                    onChange={() => setVehicleType(UscContext.Used)}
                                    defaultChecked
                                />
                                <Styles.Label htmlFor="used">Used Car</Styles.Label>
                            </Styles.RadioWrapper>
                            <Styles.RadioWrapper>
                                <Styles.Radio
                                    type="radio"
                                    id="stock"
                                    name="vehicleType"
                                    value={vehicleType}
                                    onChange={() => setVehicleType(UscContext.Stock)}
                                />
                                <Styles.Label htmlFor="stock">Stock Car</Styles.Label>
                            </Styles.RadioWrapper>
                        </Styles.GroupedButtons>

                        <ButtonSecondary fullWidth onClick={handleSubmit}>
                            Submit
                        </ButtonSecondary>
                    </Styles.ButtonWrapper>
                </Styles.Wrapper>
            </Modal.Actions>
        </ModalBaseView>
    );
};

export default VehicleForSaleIdModal;
