import React, { useRef } from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import * as Styles from "../styles/TopFilterContainerStylesV2";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { UscContext } from "../../../../../shared-logic/types/UscCommonTypes";
import TopFilters from "../../../../../../common-deprecated/features/filters/components/toyota/filters/TopFilters/TopFilters";

const TopFiltersContainer = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const carFilters = useCarFilterSelector((state) => state.carFilters);

    const titleLabel = useCarFilterLabel(
        carFilters.currentFilter === UscContext.Used ? "carFilterTitleUsed" : "carFilterTitleStock",
    );

    return (
        <Styles.Wrapper ref={containerRef} maxWidth="1600px">
            <Styles.Title>{titleLabel}</Styles.Title>
            <Styles.Container>
                <TopFilters />
            </Styles.Container>
        </Styles.Wrapper>
    );
};

export default TopFiltersContainer;
