import styled, { css } from "styled-components";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.space[3]}px;
`;

export const InputWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
`;

export const Label = styled.label`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    gap: ${theme.space[1]}px;
`;

export const Input = styled.input`
    width: 100%;
    height: 48px;
    padding: ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
    border: 1px solid ${theme.colors.grey3};
    border-radius: none;
`;

const preSuffix = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.regular};
    color: ${theme.colors.grey7};
    background-color: ${theme.colors.grey1};
    border: 1px solid ${theme.colors.grey3};
`;

export const InputSuffix = styled.span`
    ${preSuffix};
    border-inline-start: none;

    @supports not (border-inline-start: none) {
        border-${getDirection("left")}: none;
    }
`;

export const InputPrefix = styled.span`
    ${preSuffix};
    border-inline-end: none;

    @supports not (border-inline-end: none) {
        border-${getDirection("right")}: none;
    }
`;
