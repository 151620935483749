import React from "react";
import ModalBaseView from "../aem/ModalBaseView";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as Status from "./styles/StatusStyles";
import * as Flex from "../../../styles/toyota/Flexbox";
import { ModalVersionType } from "../../../utils/modalConstants";
import { ModalViewType } from "../../../types/CommonTypes";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../styles/v2/toyota/globals/IconWrapper";
import { useCommonLabel } from "../../../utils/commonLabels";

const VersionModal = (props: ModalViewType<ModalVersionType>): JSX.Element => {
    const { show, close } = props;
    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView onClose={close} show={show} size="md">
            <Modal.Header hasBackground>
                <Modal.Title>Version</Modal.Title>
                <Modal.Button type="button" aria-label={closeLabel} onClick={close}>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Status.Wrapper>
                <Status.Row>
                    <Flex.Col>
                        <b>Build: </b> {version}
                    </Flex.Col>
                </Status.Row>
                <Status.Row>
                    <Flex.Col>
                        <b>Last update: </b> {timestamp}
                    </Flex.Col>
                </Status.Row>
                <Status.Row>
                    <Flex.Col>
                        <b>Git: </b> {branch} - {commithash}
                    </Flex.Col>
                </Status.Row>
            </Status.Wrapper>
        </ModalBaseView>
    );
};

export default VersionModal;
