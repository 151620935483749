import { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import { propagateCommonQueryParamsAsString } from "../../../utils";

export enum CarConfigStep {
    Highlights = "highlights",
    Configure = "configure",
    Specs = "specs",
}

export const getCarConfigStepPath = (carConfigStep: CarConfigStep, modelId: string, carId: string): string => {
    return `${carConfigStep}/${modelId}/${carId}`;
};

export const getCarConfigUrl = (
    commonSettings: CommonSettingsType,
    modelId: string,
    carId: string,
    carConfigStep: CarConfigStep,
): string => {
    const { country, language } = commonSettings;

    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        path: getCarConfigStepPath(carConfigStep, modelId, carId),
    });

    if (commonSettings.isStandalone) {
        return `/${country}/${language}/car-config?${queryParam}`;
    }

    const modelFromMap = Object.values(commonSettings.modelMap).find(({ id }) => id === modelId);
    const baseUrl = modelFromMap?.links?.buildPage;

    if (!baseUrl) {
        return "";
    }

    const baseUrlParts = baseUrl.split("/");
    baseUrlParts[baseUrlParts.length - 2] = `${baseUrlParts[baseUrlParts.length - 2]}`;

    return `${baseUrlParts.join("/")}?${queryParam}`;
};

export const getStandaloneGradeSelectorUrl = (modelId: string, commonSettings: CommonSettingsType): string => {
    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        modelId,
    });
    return `/${commonSettings.country}/${commonSettings.language}/grade-selector?${queryParam}`;
};
