import React, { useState } from "react";
import ModalBaseView from "../aem/ModalBaseView";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as Flex from "../../../styles/toyota/Flexbox";
import * as Status from "./styles/StatusStyles";
import { ModalReqLogType } from "../../../utils/modalConstants";
import { useCommonSelector } from "../../../redux/commonStore";
import { ModalViewType } from "../../../types/CommonTypes";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../styles/v2/toyota/globals/IconWrapper";
import { RequestLogType } from "../../../utils/backendFetch";
import { useCommonLabel } from "../../../utils/commonLabels";

const RequestLogModal = (props: ModalViewType<ModalReqLogType>): JSX.Element => {
    const { show, close } = props;
    const reqLog = useCommonSelector((state) => state.utils.requestLog);
    const [openedBody, setOpenedBody] = useState<number>(-1);
    const [showStats, setShowStats] = useState<boolean>(false);

    // Its possible the same (usually cached) log is added to the reqLogs, causing duplicates.
    // Filter them in the frontend as that is easier compared to figuring this out in the backend.
    // It could also help debugging to still have all logs in the store.
    const dedupedReqLogs = reqLog.reduce<RequestLogType[]>((logs, reqlogItem) => {
        const similarLog = logs.find(
            // adding extra safeties here to avoid crashes when badly typed fetchers fill the reqLog with null/undefined
            (log) =>
                log &&
                reqlogItem &&
                log.name === reqlogItem.name &&
                log.url === reqlogItem.url &&
                log.duration === reqlogItem.duration &&
                log.method === reqlogItem.method,
        );
        if (!similarLog) {
            // Replace all credentials with placeholders
            reqlogItem.url = reqlogItem.url.replace(/[A-Za-z0-9]{8}@[0-9a-f]{32}/, "secret@secret");
            logs.push(reqlogItem);
        }
        return logs;
    }, []);

    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView onClose={close} show={show} size="md">
            <Modal.Header hasBackground>
                <Modal.Title>Request log</Modal.Title>
                <Modal.Button type="button" aria-label={closeLabel} onClick={close}>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Status.Wrapper noSpacing>
                {dedupedReqLogs.map((log, index) => {
                    let formattedDate = "";
                    let completeDate = "";
                    if (log.timestamp) {
                        const date = new Date(log.timestamp);
                        formattedDate = date.toLocaleTimeString();
                        completeDate = date.toLocaleString();
                    }

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Status.Row key={index} isClickable={!!log.body}>
                            <Flex.Col
                                width={{ xs: 1, md: 1 / 4 }}
                                onClick={() => {
                                    setOpenedBody(openedBody === index ? -1 : index);
                                }}
                            >
                                {log.name}
                                {log.method === "POST" && (
                                    <p>
                                        <code>POST{log.body && "*"}</code>
                                    </p>
                                )}
                            </Flex.Col>
                            <Flex.Col width={{ xs: 1, md: showStats ? 2 / 4 : 3 / 4 }}>
                                <a href={log.url} target="_blank" rel="noopener noreferrer">
                                    {log.url}
                                </a>
                                {openedBody === index && log.body && <br />}
                                {openedBody === index && log.body && (
                                    <code>
                                        <>{log.body}</>
                                    </code>
                                )}
                            </Flex.Col>
                            {!!log.duration && showStats && (
                                <Flex.Col width={{ xs: 1, md: 1 / 4 }} title={completeDate}>
                                    ⏱: {log.duration}ms - {formattedDate}
                                </Flex.Col>
                            )}
                        </Status.Row>
                    );
                })}
                <Status.Row>
                    <Flex.Col width={1}>
                        <a
                            href="src/common-deprecated/components/modals/status#"
                            onClick={() => setShowStats(!showStats)}
                        >
                            Show stats
                        </a>
                        {showStats && <> - ⏱: Response time + timestamp original request</>}
                    </Flex.Col>
                </Status.Row>
            </Status.Wrapper>
        </ModalBaseView>
    );
};

export default RequestLogModal;
