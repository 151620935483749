import React from "react";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { MultipleChoiceValueType } from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import FilterCheckInput from "../../common/FilterCheckInput";
import { useCarFilterSelector } from "../../../../redux/store";
import { UscContext } from "../../../../../shared-logic/types/UscCommonTypes";

type MCFilterViewType<T extends MultipleChoiceValueType> = {
    values: T[];
    selectValue: (valueId: string, selected: boolean) => void;
    columnWidth?: number;
    id: string; // Id is used to add a unique id to the input fields as it is possible that some value ides overlap.
    checkboxVariant?: boolean;
};

/**
 * View used for rendering multiple choice options. Note that this is used in other places than the default multiple choice filters.
 */
const MCFilterView = <T extends MultipleChoiceValueType>(props: MCFilterViewType<T>): JSX.Element => {
    const { values, selectValue, columnWidth, id, checkboxVariant } = props;

    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const hideZeroResultCountFilters = useCarFilterSelector((state) =>
        currentFilter === UscContext.Used
            ? state.carFilterSettings.usedCarsHideZeroResultCountFilters
            : state.carFilterSettings.stockCarsHideZeroResultCountFilters,
    );

    return (
        <Flex.Row>
            {values
                .sort((a, b) => {
                    // Put values with no results last, otherwise keep the sort order unchanged.
                    if (a.resultCount === 0 && b.resultCount !== 0) return 1;
                    else if (a.resultCount !== 0 && b.resultCount === 0) return -1;
                    else return 0;
                })
                .map((val, index) =>
                    // !0 is true and !undefined is true, so don't show the filter if resultCount is 0 or undefined
                    !!val.resultCount || !hideZeroResultCountFilters ? (
                        <Flex.Col
                            width={columnWidth}
                            key={val.id}
                            display="flex"
                            justifyContent="flex-start"
                            // No padding for last value for easy layouting.
                            pb={index === values.length - 1 ? 0 : 15}
                        >
                            <FilterCheckInput
                                checked={val.selected}
                                label={val.label}
                                onChange={() => selectValue(val.id, !val.selected)}
                                disabled={val.resultCount === 0}
                                id={`${id}-${val.id}`}
                                resultCount={val.resultCount}
                                checkboxVariant={checkboxVariant}
                            />
                        </Flex.Col>
                    ) : null,
                )}
        </Flex.Row>
    );
};

export default MCFilterView;
