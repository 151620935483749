import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { FullQuickSpecConfigType, QuickSpecConfigType } from "../../../shared-logic/utils/uscUtils";
import { UscContext, UsedCarResultType } from "../../../shared-logic/types/UscCommonTypes";
import { useCarFilterSelector } from "../../redux/store";
import { amendQuickSpecConfigs, getUscLegalSpecConfigs } from "../../../shared-logic/utils/uscSpecUtils";
import { carFilterSpecsParamsSettingsSelector, carFilterWeightedSpecsLabelSelector } from "../../utils/specUtils";
import { uscNedcWltpDisclaimersLabelSelector } from "../../../shared-logic/utils/uscEcoUtils";

type useUsedCarResultSpecsReturnType = {
    shouldShowMore: boolean;
    specs: FullQuickSpecConfigType[];
};

/**
 * Maximum number of specs to display when not in overlay. This is not the true maximum,
 * as legal specs are always displayed. Though in the case where legal specs < 6, the
 * remaining space is filled with normal specs up until the maximum defined below.
 */
const MAX_CARD_SPECS = 4;
// Minimum number of specs when not in the overlay.
const MIN_CARD_SPECS = 2;

const useUsedCarResultSpecs = (result: UsedCarResultType, inOverlay?: boolean): useUsedCarResultSpecsReturnType => {
    const [shouldShowMore, setShouldShowMore] = useState(false);
    const [formattedSpecs, setFormattedSpecs] = useState<FullQuickSpecConfigType[]>([]);

    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const settings = useCarFilterSelector(carFilterSpecsParamsSettingsSelector, shallowEqual);
    const nedcWltpDisclaimerLabels = useCarFilterSelector(uscNedcWltpDisclaimersLabelSelector, shallowEqual);
    const weightedSpecsLabels = useCarFilterSelector(carFilterWeightedSpecsLabelSelector, shallowEqual);

    const quickSpecConfigs = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) =>
            carFilters.currentFilter === UscContext.Used
                ? carFilterSettings.usedCarDropdownSpecConfig
                : carFilterSettings.stockCarDropdownSpecConfig,
        shallowEqual,
    );
    const legalSpecConfigs = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) =>
            getUscLegalSpecConfigs(
                carFilters.currentFilter === UscContext.Used
                    ? carFilterSettings.usedCarLegalSpecConfig
                    : carFilterSettings.stockCarLegalSpecConfig,
                result.product.engine.marketingFuelType.code,
            ),
        shallowEqual,
    );

    useEffect(() => {
        const specParams = {
            commonSettings,
            settings,
            labels: { nedcWltpDisclaimerLabels, quickSpecLabels: null, weightedSpecsLabels },
        };

        const specs = amendQuickSpecConfigs(
            [...legalSpecConfigs, ...quickSpecConfigs] as QuickSpecConfigType[],
            specParams,
            result,
            result.dealer,
        );
        const legalSpecs = specs.filter(({ isLegal }) => isLegal);
        const quickSpecs = specs.filter(({ isLegal }) => !isLegal);

        let displaySpecs = [...legalSpecs]; // Always show _all_ legal specs

        // Add normal specs if there's space
        let maxNormalSpecs: number;
        if (inOverlay) {
            displaySpecs = [...displaySpecs, ...quickSpecs];
        } else if (displaySpecs.length < MAX_CARD_SPECS) {
            maxNormalSpecs = Math.max(MIN_CARD_SPECS, MAX_CARD_SPECS - legalSpecs.length);
            displaySpecs = [...displaySpecs, ...quickSpecs.slice(0, maxNormalSpecs)];
        }

        setFormattedSpecs(displaySpecs);
        setShouldShowMore(displaySpecs.length < specs.length || !!inOverlay);
    }, [
        commonSettings,
        inOverlay,
        legalSpecConfigs,
        nedcWltpDisclaimerLabels,
        quickSpecConfigs,
        result,
        settings,
        weightedSpecsLabels,
    ]);

    return { shouldShowMore, specs: formattedSpecs };
};

export default useUsedCarResultSpecs;
