import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { breakpoints } from "../../../../../../shared-logic/themes/toyotaTheme";

export const Wrapper = styled.div<{ show: boolean }>`
    transition: opacity 0.15s;
    opacity: ${(props) => (props.show ? 1 : 0)};

    @media screen and (min-width: ${breakpoints.xl}) {
        margin: 0 auto;
        text-align: center;
    }
`;

export const Value = styled.span`
    padding: 0 ${theme.space[1]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
`;

export const Label = styled.span`
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};

    @media screen and (min-width: ${breakpoints.lg}) {
        color: ${theme.colors.grey7};
    }
`;
