import { CommonSettingsType } from "../../settings/fetchCommonSettings";
import { EnvironmentEnum, parseProp, removeEmptyPropsOfObject, RenderMethod } from "../../utils";
import { commonLabels } from "../../utils/commonLabels";
import {
    COMMON_SETTINGS_INIT,
    CommonSettingsActionsType,
    UPDATE_QUERY_PARAMS,
    UPDATE_QUERY_PATH,
    UPDATE_QUERY_VALUE,
} from "../actions/CommonSettingsActions";

export const commonSettingsInitialState: CommonSettingsType = {
    environment: EnvironmentEnum.Development,
    isStandalone: true, // describes if output is rendered via page (GET) or component (POST) endpoint, completely different from query.isStandalone
    component: "buildandbuy", // This is not the actual component name as we don't want to add this dependency by default.
    brand: "toyota",
    country: "gb",
    language: "en",
    aemFlexibilityMatrix: null,
    urlWebsite: "",
    urlWebsiteInjection: "",
    servicesHost: "",
    resourcePath: "http://localhost:5001",
    apiEndpoint: "http://localhost:5001/v1/api",
    ccisPath: "",
    compressedCcisPath: "",
    cardbImageHost: "",
    cardbAssetPath: "",
    pdfService: "",
    enableTokens: true,
    culture: {} as CommonSettingsType["culture"],
    query: {},
    body: {},
    reqUserAgent: "",
    renderMethod: RenderMethod.Server,
    layoutOptions: { noContainerSpacing: false },
    modelMap: {},
    promoToken: "",
    mapboxToken: "",
    showPrice: true,
    ecoBadges: {},
    tyreLabelService: "",
    tyreLabelFallbackService: "",
    ccGateway: "",
    currencyMultiplier: 0,
    ecoLabelpopup: null,
    labels: { ...commonLabels },
    intl: {
        currencyCode: "EUR",
        currencyDecimals: 2,
    },
};

const CommonSettingsReducer = (
    state: CommonSettingsType = commonSettingsInitialState,
    action: CommonSettingsActionsType,
): CommonSettingsType => {
    switch (action.type) {
        case COMMON_SETTINGS_INIT: {
            return { ...state, ...action.initState };
        }

        case UPDATE_QUERY_PATH: {
            return {
                ...state,
                query: {
                    ...state.query,
                    path: action.path,
                },
            };
        }

        case UPDATE_QUERY_PARAMS: {
            return {
                ...state,
                query: removeEmptyPropsOfObject({ ...state.query, ...parseProp(action.params) }),
            };
        }

        case UPDATE_QUERY_VALUE:
            const { update } = action;

            return {
                ...state,
                query: {
                    ...state.query,
                    ...update,
                },
            };

        default:
            return state;
    }
};

export default CommonSettingsReducer;
