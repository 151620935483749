import { getLabelComponent, LabelComponentType } from "../../../../common-deprecated/components/label/Label";
import {
    getLabelFn,
    GetLabelType,
    getUseLabel,
    getUseOptionalLabel,
    UseLabelType,
} from "../../../../common-deprecated/hooks/useLabel";
import { DfCarSortOrder } from "./filterConstants";
import { CarFilterStateType } from "../../redux/store";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { CarFilterSettingsType } from "../../redux/reducers/CarFilterSettingsReducer";

export const carFilterHeaderLabels = {
    carFilterHeaderTitleUsed: "Used cars",
    carFilterHeaderTitleStock: "Stock cars",
    carFilterHeaderDescription: "Don't miss the perfect opportunity, book it easily online",
    carFilterHeaderShowResults: "Show {value} results",
};

export const carFilterLabels = {
    ...carFilterHeaderLabels,
    carFilterTypeOfCar: "Type of car",
    carFilterCategory: "Category",
    carFilterFuel: "Fuel",
    carFilterBrands: "Brands",
    carFilterModels: "Models",
    carFilterAllModels: "All {brand} models",
    carFilterSearchBrandModels: "Search brands and models",
    carFilterSearchModels: "Search models",
    carFilterBack: "Back",
    carFilterPriceCash: "Cash",
    carFilterPriceMonthly: "Monthly",
    carFilterShowBiTone: "Show Bi-Tone coloured vehicles",
    carFilterViewZeroMileage: "View only 0 km cars",
    carFilterAnyWarrantyTitle: "Toyota approved used",
    carFilterAnyWarrantyOption: "Show only TOYOTA PLUS cars",
    carFilterAnyWarrantyDescription: "",
    carFilterWarrantyDuration: "Duration",
    carFilterResultCompare: "Compare",
    carFilterResultModelYear: "Model year",
    carFilterShowAllFilters: "Show all filters",
    carFilterClearFilter: "Clear {value} filter",
    carFilterClearFilters: "Clear {value} filters",
    carFilterSeeResult: "See {value} result",
    carFilterSeeResults: "See {value} results",
    carFilterNoResults: "No results",
    carFilterAllFilters: "All filters",
    carFilterResultCount: "{value} results",
    carFilterSort: "Sort",
    carFilterLoadMore: "Load more",
    carFilterShowResults: "Showing {value} results",
    carFilterLoadingResults: "{value} results loading",
    carFilterShowResultsCounter: "{value} of {total}",
    carFilterSortPublished: "Latest",
    carFilterSortCashAsc: "Cash Price - Lowest",
    carFilterSortCashDesc: "Cash Price - Highest",
    carFilterSortMonthlyAsc: "Monthly Price - Lowest",
    carFilterSortMonthlyDesc: "Monthly Price - Highest",
    carFilterSortDistance: "Distance",
    carFilterSortModel: "Model name",
    carFilterSortYearAsc: "Year - Oldest",
    carFilterSortYearDesc: "Year - Newest",
    carFilterSortMileageAsc: "Mileage - Lowest",
    carFilterSortMileageDesc: "Mileage - Highest",
    carFilterSortBrand: "Brand name",
    carFilterUsedFilter: "Used cars",
    carFilterInStock: "in stock",
    carFilterLocationPlaceholder: "Postcode, dealership or location",
    carFilterLocationNoResults: "No location found",
    carFilterLocationError: "Location search failed",
    carFilterLocationNational: "National",
    carFilterLocationRadius: "Radius",
    carFilterLocationRadiusLabel: "Select location radius",
    carFilterLocationDealerCount: "{value} dealerships",
    carFilterLocationRadiusDisclaimerTitle: "",
    carFilterLocationRadiusDisclaimer: "",
    carFilterLocationItemsTitle: "Locations",
    carFilterLocationDealershipsTitle: "Dealerships",
    carFilterLocationHubDealer: "Dealer of your choice",
    carFilterSentenceSeparator: ", ",
    carFilterSentenceFinalSeparator: " and ",
    carFilterLicenseEven: "Even",
    carFilterLicenseOdd: "Odd",
    carFilterOwnerReviews: "Owner reviews",
    carFilterEtaWeeks: "Delivery in {value} weeks",
    carFilterEtaWeeksSingle: "Delivery this week",
    carFilterPromo: "Promotion",
    carFilterPromoFromDate: "Valid from {from}",
    carFilterPromoUntilDate: "Valid until {until}",
    carFilterPromoFromUntilDate: "Valid from {from} until {until}",
    carFilterColourCount: "{value} more colours",
    carFilterZeroMileage: "", // Any mileage
    carFilterLocationDistance: "{value} km",
    carFilterNoResultsTitle: "Oops, no result",
    carFilterNoResultsDescription: "Try to remove some of the filters",
    carFilterClearAllFilters: "Remove all filters",
    carFilterPaginationOutOfBoundsTitle: "Oops, the page you are looking for does not exist",
    carFilterPaginationOutOfBoundsDescription: "Go back to the first page",
    carFilterPaginationOutOfBoundsButton: "Go back",
    carFilterSaveCar: "Save",
    carFilterSavedCar: "Saved",
    carFilterNoSavedCars: "No saved cars",
    carFilterShowSavedCars: "Show saved cars",
    carFilterSimilarCarTitle: "Your perfect Toyota is a click away",
    carFilterSimilarCarDescription: "Search our certified collection for your next car.",
    carFilterSimilarCarButton: "View all cars",
    carFilterSimilarCarCounter: "{value} Similar Cars",
    carFilterSimilarCarHeaderTitle: "Discover similar used cars",
    carFilterPageDisclaimerTitle: "Disclaimers",
    carFilterPageDisclaimerUsed: "",
    carFilterPageDisclaimerStock: "",
    carFilterComingSoon: "Coming soon",
    carFilterOnlineReservation: "Online Reservation available",
    carFilterTitleUsed: "Our used cars",
    carFilterTitleStock: "Our stock cars",
    carFilterDeliverable: "Car Delivery",
    carFilterAvailableForDelivery: "Available for delivery",
    carFilterOtherBrandsTitle: "Other brands",
    carFilterSelectBrandDisclaimer: "Select a brand first",
    carFilterWeightedCombinedCo2: "CO2 combined - Weighted",
    carFilterWeightedCo2NedcCombined: "CO2 Nedc - Weighted",
    carFilterWeightedCo2WltpCombined: "CO2 WLTP - Weighted",
    carFilterWeightedFcNedcCombined: "Fuel consumption Nedc combined - Weighted",
    carFilterWeightedFcWltpCombined: "Fuel consumption WLTP combined - Weighted",
    carFilterWeightedEcCombined: "Electric consumption combined - Weighted",
    carFilterWeightedFcCsCombined: "Fuel consumption charge sustaining - Weighted",
    carFilterWeightedEfficiencyClassText: "Efficiency class - Weighted",
    carFilterContactDealer: "Contact dealer",
    carFilterSelectCar: "Select car",
    carFilterMoreBenefits: "More benefits",
    carFilterContactDealerCta: "Contact dealer",
    carFilterContactDealerModalTitle: "Information Request",
    carFilterShowMore: "Show more",
    carFilterAriaPagination: "Pagination",
    carFilterAriaPreviousPage: "Previous page",
    carFilterAriaNextPage: "Next page",
    carFilterAriaPage: "Page {value}",
};

export type CarFilterLabelType = keyof typeof carFilterLabels;
export type CarFilterLabelStateType = {
    commonSettings: CommonSettingsType;
    carFilterSettings: CarFilterSettingsType;
};
type CarFilterLabelParamType = Partial<Record<"value" | "brand" | "total" | "from" | "until", string | number>>;

export const sortOrderLabelMap: Record<DfCarSortOrder, CarFilterLabelType> = {
    [DfCarSortOrder.Published]: "carFilterSortPublished",
    [DfCarSortOrder.CashAsc]: "carFilterSortCashAsc",
    [DfCarSortOrder.CashDesc]: "carFilterSortCashDesc",
    [DfCarSortOrder.MonthlyDesc]: "carFilterSortMonthlyDesc",
    [DfCarSortOrder.MonthlyAsc]: "carFilterSortMonthlyAsc",
    [DfCarSortOrder.Distance]: "carFilterSortDistance",
    [DfCarSortOrder.YearAsc]: "carFilterSortYearAsc",
    [DfCarSortOrder.YearDesc]: "carFilterSortYearDesc",
    [DfCarSortOrder.MileageAsc]: "carFilterSortMileageAsc",
    [DfCarSortOrder.MileageDesc]: "carFilterSortMileageDesc",
    [DfCarSortOrder.Brand]: "carFilterSortBrand",
};

export const getCarFilterLabel = getLabelFn(
    (state: CarFilterLabelStateType) => state.carFilterSettings.labels,
    (state: CarFilterLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<CarFilterLabelStateType, CarFilterLabelType, CarFilterLabelParamType>;

export const useCarFilterLabel = getUseLabel(
    (state: CarFilterStateType) => state.carFilterSettings.labels,
    (state: CarFilterStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<CarFilterLabelType, CarFilterLabelParamType>;

export const useCarFilterOptionalLabel = getUseOptionalLabel(
    (state: CarFilterStateType) => state.carFilterSettings.labels,
    (state: CarFilterStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<CarFilterLabelType, CarFilterLabelParamType>;

export const CarFilterLabel = getLabelComponent(useCarFilterLabel) as LabelComponentType<
    CarFilterLabelType,
    CarFilterLabelParamType
>;
