import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import { IconControls, IconPlus, IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import SortFilters from "./SortFilters";
import { showMoreFilters } from "../../../../redux/actions/CarFiltersActions";
import FilterResultCount from "./FilterResultCount";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import { enabledSavedCarsSelector } from "../../../../utils/selectorUtils";
import * as Styles from "./styles/StaticFilterBarStyles";

type StaticFilterBarType = { isFixed: boolean };

const StaticFilterBar = ({ isFixed }: StaticFilterBarType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const showFilters = useCarFilterSelector((state) => state.carFilters.showMoreFilters);
    const savedCarsEnabled = useCarFilterSelector(enabledSavedCarsSelector, shallowEqual);

    const allFiltersLabel = useCarFilterLabel("carFilterAllFilters");

    return (
        <Styles.Border>
            <Styles.Wrapper maxWidth="1600px" isFixed={isFixed} savedCarsEnabled={savedCarsEnabled}>
                <Styles.Inner isFixed={isFixed}>
                    <div>
                        <Styles.Button
                            type="button"
                            onClick={() => dispatch(showMoreFilters(!showFilters))}
                            isActive={showFilters}
                        >
                            <div>
                                <IconControls />
                                <Ellipsis maxWidth={{ _: "100%" }}>{allFiltersLabel}</Ellipsis>
                            </div>
                            {showFilters ? <IconClose /> : <IconPlus />}
                        </Styles.Button>
                    </div>

                    <Styles.FilterResultCount>
                        <FilterResultCount show />
                    </Styles.FilterResultCount>

                    <SortFilters />
                </Styles.Inner>
            </Styles.Wrapper>
        </Styles.Border>
    );
};

export default StaticFilterBar;
