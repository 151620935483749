import { useEffect, useRef, useState } from "react";
import { useCommonSelector } from "../redux/commonStore";
import { useCloseOnOutsideClick, useDisableBodyScroll } from "../hooks";
import { SALESMAN_SCROLL_OVERLAY } from "../constants";
import { sendJsonToSalesman } from "../utils";
import { isMaster } from "../settings/utils/commonSettingUtils";

type ModalBaseViewHookType = {
    onClose: () => void;
    show: boolean;
    disableOutsideClick?: boolean;
};

const useModalBaseView = (props: ModalBaseViewHookType): React.RefObject<HTMLDivElement> | null => {
    const { onClose, disableOutsideClick, show } = props;
    const modalRef = useRef<HTMLDivElement>(null);

    // Don't disable the body scroll when the modal is rendered but not shown
    useDisableBodyScroll(!show);

    // Cheap hack: Because the modals are rendered next to each other (and not inside eachother)
    // Clicking on anything in a second modal triggers the useCloseOnOutsideClick instance of the first one
    // This code disables the hook for all but the last modal by checking if it is the last node in it's parent element
    const allModals = useCommonSelector((state) => state.modal.modals);
    const [isCurrentModal, setCurrentModal] = useState(false);
    useEffect(() => {
        setCurrentModal(!modalRef.current?.parentElement?.nextElementSibling); // is current when parent element has no next siblings
    }, [allModals]);
    useCloseOnOutsideClick(modalRef, onClose, disableOutsideClick || !isCurrentModal);

    // Hide modal on Escape key. Not a requirement but its here for DX.
    useEffect(() => {
        if (isCurrentModal) {
            const toggleKey = (event: KeyboardEvent): void => {
                if (event.key.toLowerCase() === "escape") onClose();
            };

            window.addEventListener("keydown", toggleKey);
            return () => window.removeEventListener("keydown", toggleKey);
        }
    }, [isCurrentModal, onClose]);

    // Billboard / Salesman modal scroll syncing
    const settings = useCommonSelector((state) => state.commonSettings);
    useEffect(() => {
        if (!isCurrentModal || !modalRef.current || !isMaster(settings)) return;

        const bodyElement = modalRef.current.querySelector(".or-modal-body");
        if (!bodyElement) return;

        const onScroll = (): void =>
            sendJsonToSalesman({ action: SALESMAN_SCROLL_OVERLAY, data: bodyElement.scrollTop >> 0 });
        bodyElement.addEventListener("scroll", onScroll);
        return () => bodyElement.removeEventListener("scroll", onScroll);
    }, [modalRef.current]);

    return modalRef;
};

export default useModalBaseView;
