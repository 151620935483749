import React, { ReactNode } from "react";
import FocusTrap from "focus-trap-react";
import * as Styles from "../../../styles/v2/toyota/globals/Modal";
import { BreakPointValuesType } from "../../../themes/common";
import useModalBaseView from "../../../hooks/useModalBaseView";
import { MODAL_DIALOG_ID } from "../../../utils";
import { useCommonSelector } from "../../../redux/commonStore";

type ModalBaseViewType = {
    onClose: () => void;
    onTransitionEnd?: () => void;
    show: boolean;
    children: ReactNode;
    size?: BreakPointValuesType;
    contentClass?: string;
    disableOutsideClick?: boolean;
    isFullscreen?: boolean;
    fullWidth?: boolean;
    hasFadeInAnimation?: boolean;
    focusTrap?: boolean;
};

/**
 * This wrapper should be used for every Modal component
 * It contains required modal wrappers and the overlay background.
 */
const ModalBaseView = (props: ModalBaseViewType): JSX.Element => {
    const { show, children, size, fullWidth, isFullscreen } = props;
    const { onTransitionEnd, contentClass, onClose, disableOutsideClick, hasFadeInAnimation, focusTrap = true } = props;
    const modalRef = useModalBaseView({ onClose, disableOutsideClick, show });

    // AEM Author - modals should not cover other components and should be top aligned so editors see them immediately
    const isAuthor = useCommonSelector((state) => state.commonSettings?.query?.authorPreview || false);

    return (
        <FocusTrap
            focusTrapOptions={{
                tabbableOptions: { getShadowRoot: () => false },
                allowOutsideClick: true,
            }}
            active={focusTrap}
        >
            <Styles.Wrapper role="dialog" isActive={show} onTransitionEnd={onTransitionEnd} isAuthor={isAuthor}>
                <Styles.Dialog
                    id={MODAL_DIALOG_ID}
                    data-cpdqm="ignore"
                    ref={modalRef}
                    isActive={show}
                    hasFadeInAnimation={hasFadeInAnimation}
                    size={size}
                    isFullscreen={isFullscreen}
                    fullWidth={fullWidth}
                    isAuthor={isAuthor}
                >
                    <Styles.Content className={contentClass}>{children}</Styles.Content>
                </Styles.Dialog>
            </Styles.Wrapper>
        </FocusTrap>
    );
};

export default ModalBaseView;
